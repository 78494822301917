import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IResponse } from './../../interfaces/IResponse';
import { SharedService } from './../../../shared/shared.service';

@Component({
  selector: 'tradestrat-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  getError: string;
  verifyPassword = false;
  failedSending = false;
  getSuccess: string;
  verifiedCode = '';
  startTimer = 0;
  loginBackground = '';
  logoImage = '';


  constructor(private loginService: AuthService, private sharedService: SharedService,
              private router: Router) { }

  ngOnInit(): void {
    this.logoImage = this.sharedService.logoImage ? this.sharedService.logoImage : './assets/images/Logos/logo.png';

    this.loginBackground = this.sharedService.loginBackground ? this.sharedService.loginBackground : './assets/images/BackgroundImages/login.png';
    this.resetPasswordForm = new FormGroup({
      username: new FormControl(null, [...this._Validators()])
    });

  }
  _Validators(): any {
    return [Validators.required];
  }
  onSubmitForgetPassForm(): void {
    this.failedSending = false;
    this.verifyPassword = false;

    if (this.resetPasswordForm.valid) {
      this.loginService.forgetPassword(this.resetPasswordForm.value.username).subscribe((res: IResponse<any>) => {
        if (res.status.code === 0) {
          this.verifyPassword = true;
          this.startCounter();

          // this.getSuccess = 'login.mailSent';
          // this.getError = this.errorHandlingService.handleError(err, 'resetError');
        } else {
          this.failedSending = true;
        }
      },
        err => {
          this.failedSending = true;
        });
    }
  }
  startCounter(): void {
    this.startTimer = 50;
    setInterval(() => {
      if (this.startTimer === 0) {
        return;
      } else {
        this.startTimer--;
      }
    }, 1000);
  }
  verifyCode(): void {
    this.loginService.verifyForgetPassword(this.resetPasswordForm.value.username, this.verifiedCode).subscribe((res: any) => {

    });
  }

}
