import {
  Component,
  ContentChildren,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { TableColumnFilterComponent } from '../filter/table-column-filter.component';
import {
  ClearColumnFilter,
  ColumnFilter,
  TableColumnFilterType,
} from '../filter/table-column-filter.model';
import { filterTableData, TableService } from './table.service';
import { TableItemDirective } from './table-item.directive';
import { map, shareReplay, takeUntil, takeWhile } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ColumManagmentComponent } from './table-managment.component';
import { getFirstValue } from '../../../core/utils/operators';
import { orderBy } from 'lodash';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Header } from '../multi-group-table/multi-group-table.model';

@Component({
  selector: 'tradestrat-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [TableService],
})
export class TableComponent<T>
  implements OnInit, OnChanges, OnDestroy, DoCheck
{
  modalRef: BsModalRef<ConfirmationModalComponent>;

  constructor(
    public tableService: TableService<T>,
    private modalService: BsModalService,
    private iterableDiffers: IterableDiffers
  ) {}

  @Input() idKey = 'id';
  @Input() public isFilteringEnabled = false;
  @Input() public isFilterClearBtnEnabled = true;
  @Input() public hasPaginator = true;
  @Input() public isActionColumnEnabled = false;
  @Input() public data!: any[];
  @Input() public sharedData: T[] = [];
  @Input() public disableColumnConfiguration?: boolean;
  @Input() public isSortEnabled = true;
  @Input() public isCheckRowEnabled = false;
  @Input() public isSortServerSide = false;
  @Input() public isFilteringServerSide = false;
  @Input() public openColumnManagementExternally = false;
  @Input() public isBordered = true;
  @Input() public columnsManagementExternally: Header[] = [];
  @Input() public innerBorderRadius = true;
  @Output() public sharedDataUpdate = new EventEmitter<T[]>();
  @Output() public filterChanges = new EventEmitter();
  @Output() public sortChanges = new EventEmitter();
  @Output() public paginationChanges = new EventEmitter();
  @Output() public editRowChanges = new EventEmitter();
  @Output() public deleteRowChanges = new EventEmitter();
  private destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  private _isAllSelected: boolean;

  public get isAllSelected(): boolean {
    return this.tableData.every((item) => item.checked);
  }

  current = 1;
  page = 1;
  pageSize = 5;
  private columnsManagementChanges = new BehaviorSubject<Header[]>([]);
  public tableData!: readonly any[] | null;
  public sortOrder = SortOrder;

  @ContentChildren(TableItemDirective, { descendants: true })
  public set columns(value: QueryList<TableItemDirective<T>>) {
    this.columnsSubject.next(value.toArray());
  }

  public readonly columnsSubject = new ReplaySubject<
    Array<TableItemDirective<T>>
  >(1);

  @ViewChildren(TableColumnFilterComponent)
  public readonly columnFilterFields!: QueryList<TableColumnFilterComponent>;
  private readonly defaultColumnsChanges = this.columnsSubject.pipe(
    map((columns) => columns.filter((column) => column.visible))
  );

  public readonly displayedColumnsChanges = combineLatest([
    this.columnsSubject,
    this.defaultColumnsChanges,
    this.tableService.displayedColumnsChanges,
    this.columnsManagementChanges,
  ]).pipe(
    map(([columns, defaultColumns, columnConfigs, columnConfigsExt]) => {
      const result = defaultColumns;
      if (columnConfigs) {
        return columnConfigs.filter((column) => column.visible);
      }
      if (columnConfigsExt) {
        return result
          .map((column) => {
            const externalColumn = columnConfigsExt.find(
              (item) => item.key === column.key
            );
            if (externalColumn) {
              column.visible = externalColumn.isVisible;
            }
            return column;
          })
          .filter((column) => column.visible);
      }

      return result;
    }),
    shareReplay(1)
  );
  public tableColumnFilterType = TableColumnFilterType;

  public get areColumnsConfigurable(): boolean {
    if (this.disableColumnConfiguration) {
      return false;
    }
    return !!this.tableService;
  }

  public get isUtilityColumnVisible(): boolean {
    return (
      this.areColumnsConfigurable ||
      (this.isFilterClearBtnEnabled && this.isFilteringEnabled)
    );
  }

  ngOnInit(): void {
    this.tableService.filterChanges.subscribe((filterData) => {
      this.filterChanges.emit(filterData);
      if (this.isFilteringServerSide) {
        return;
      }
      this.tableData = filterTableData(
        this.data,
        filterData,
        this.tableService.getFilterType()
      );
      this.sharedDataUpdate.emit([...this.tableData]);
    });
    combineLatest([
      this.tableService.sortChanges,
      this.tableService.latestSortValueChanges,
    ])
      .pipe(takeWhile(() => !this.isSortServerSide))
      .subscribe((sortValues) => {
        const sortData = sortValues[0];
        const lastSortValue = sortValues[1];
        this.sortChanges.emit(sortData);
        const sortObjKeys = Object.keys(sortData).filter(
          (sortKey) => sortKey === lastSortValue
        );
        this.tableData = orderBy(
          this.data,
          sortObjKeys.map((key) => {
            return (collection: T) =>
              typeof collection[key] === 'string'
                ? collection[key].toLowerCase()
                : collection[key];
          }),
          sortObjKeys.filter((key) => sortData[key]).map((key) => sortData[key])
        );
        this.sharedDataUpdate.emit([...this.tableData]);
      });
    this.pageSize = this.hasPaginator ? this.pageSize : this.data.length;
    this.sharedData = [...this.data];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue !== changes.data?.previousValue) {
      this.tableData = this.data ? [...this.data] : [];
      this.sharedData = [...this.tableData];
    }

    // controlling ColumnManagement modal externally
    this._controlColumnModalExternally(changes);

    // force update shared tables
    this._forceUpdateTableData(changes);
  }

  ngDoCheck(): void {
    // update column Management
    const deebChanges = this.iterableDiffers.find(
      this.columnsManagementExternally
    );

    if (deebChanges) {
      this.columnsManagementChanges.next(this.columnsManagementExternally);
    }
  }

  private _controlColumnModalExternally(changes: SimpleChanges): void {
    if (
      !changes.openColumnManagementExternally?.isFirstChange() &&
      changes.openColumnManagementExternally?.currentValue !==
        changes.openColumnManagementExternally?.previousValue
    ) {
      this.openColumnManagement();
    }
  }

  private _forceUpdateTableData(changes: SimpleChanges): void {
    if (
      !changes.sharedData?.isFirstChange() &&
      changes.sharedData?.currentValue !== changes.sharedData?.previousValue
    ) {
      this.tableData = [...this.sharedData];
    }
  }

  public updateFilter(value: ColumnFilter | ClearColumnFilter): void {
    if (value.isCleared) {
      this.tableService?.removeFilter(value.key);
    } else {
      this.tableService?.addFilter(value);
    }
  }

  public clearFilters(): void {
    if (!this.tableService) {
      return;
    }
    this.tableService.clearFilters();
    this.columnFilterFields.forEach((field) => field.clear());
  }

  async openColumnManagement(): Promise<void> {
    const initialState = {
      columns: await getFirstValue(this.tableService.displayedColumnsChanges),
      tableService: this.tableService,
    };
    if (!initialState.columns) {
      initialState.columns = await getFirstValue(this.columnsSubject);
    }
    this.modalService.show(ColumManagmentComponent, { initialState } as any);
  }

  public onSort(tableItem: TableItemDirective<unknown>): void {
    const currentSortValue = this.tableService.getSort(tableItem);
    this.tableService.addSort(tableItem, toggleSortOrder(currentSortValue));
  }

  public pageIndexChange(index): void {
    this.page = index;
    this.paginationChanges.emit({
      page: this.page,
      pageSize: this.pageSize,
    });
  }

  public pageSizeChange(size): void {
    this.pageSize = size;
    this.paginationChanges.emit({
      page: this.page,
      pageSize: this.pageSize,
    });
  }

  openDeleteModal(record: T): void {
    this.modalRef = this.modalService.show(ConfirmationModalComponent);
    this.modalRef.content.headerTitle = 'deleteTenant';
    this.modalRef.content.modalMessage = 'deleteTenant';
    this.modalRef.content.data = record;
    this.modalRef.content.submitFunction
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => this.deleteRowChanges.emit(data));
  }

  selectAll(e: any): void {
    if (!(e instanceof Event)) {
      this.data.forEach((item, index, data) => {
        const isItemExistsInView = this.tableData.find(
          (tableItem) => tableItem[this.idKey] === item[this.idKey]
        )
          ? true
          : false;
        if (e) {
          // select all
          // if item is already checked then don't change its state
          data[index].checked = data[index].checked
            ? data[index].checked
            : isItemExistsInView;
        } else {
          // deselect all
          // if item is already unchecked then don't change its state
          data[index].checked = data[index].checked
            ? !isItemExistsInView
            : data[index].checked;
        }
      });
    }
  }

  checkRow(e: any, row: any): void {
    if (!(e instanceof Event)) {
      const elementIndex = this.data.findIndex(
        (item) => item[this.idKey] === row[this.idKey]
      );
      if (elementIndex >= 0) {
        row.checked = e;
        this.data[elementIndex].checked = e;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}

export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

function toggleSortOrder(value: SortOrder | undefined): SortOrder | null {
  switch (value) {
    case undefined:
      return SortOrder.Ascending;
    case SortOrder.Ascending:
      return SortOrder.Descending;
    case SortOrder.Descending:
      return undefined;
    default:
      throw new Error('sort order not valid value');
  }
}
