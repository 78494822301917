import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {
  IItemListsModel,
  IWaterFullTypesDTO,
  WaterFullTableHeaderDTo
} from '../../../features/admin/data-management/models/data-management-model';

@Component({
  selector: 'tradestrat-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {

  @Input() title: string;
  @Input() tableHeader: WaterFullTableHeaderDTo[];
  @Input() listCard: IItemListsModel[];
  @Input() type: string;
  @Input() checkBoxStatus: boolean;
  @Input() checkBoxDeductionStatus: boolean;
  @Input() invoiceStatusToggle: boolean;
  @Input() deductedFromSubSumToggle: boolean;
  @Input() addNewItemButtonStatus: boolean;
  @Input() removeAllStatus: boolean;
  @Input() addNewItemButtonTitle: string;
  @Output() public addItemFunction = new EventEmitter();
  @Output() public editItemFunction = new EventEmitter();
  @Output() public deleteItemFunction = new EventEmitter();
  @Output() public setOnInvoicesListFunction = new EventEmitter<{ event: string, index: number, list: IItemListsModel[] }>();

  constructor() {
  }

  ngOnInit(): void {
  }

  editItem(item): void {
    this.editItemFunction.emit(item);
  }

  deleteItem(item): void {
    this.deleteItemFunction.emit(item);
  }

  addItem(type): void {
    if (type.code === 'PRODUCT_ATTRIBUTES') {
      this.addItemFunction.emit({type});
    } else {
      this.addItemFunction.emit({type, latestApplicationBasis: this.listCard[this.listCard.length - 1]?.applicationBasis});
    }
  }

  selectInvoice(event, row, index): void {
    const outputObject = {event, index, list: this.listCard};
    this.setOnInvoicesListFunction.emit(outputObject);
  }
}
