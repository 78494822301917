import { SharedService } from './shared/shared.service';
import {ChangeDetectorRef, Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'tradestrat-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy{
  title = 'tradestrat';
  show = false;
  isLoading$: Subscription;
  currentLang = 'en';
  constructor(private sharedSerivce: SharedService, private cdr: ChangeDetectorRef, private translate: TranslateService) {
    translate.setDefaultLang(this.currentLang);
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.isLoading$ = this.sharedSerivce.loaderSubject
      .subscribe((state: any) => {
        this.show = state.show;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.isLoading$.unsubscribe();

  }
}
