import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tradestrat-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() dataForTabs: any;
  @Input() chips: boolean;
  @Input() disabled: boolean;
  @Output() public activatedTab = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    this.disabled = false;
  }

  onSelect(event): void {
    this.activatedTab.emit(event.id);
  }
}
