import {
  SharedService
} from './../../shared/shared.service';
import {
  HttpErrorResponse
} from '@angular/common/http';

import {
  Injectable
} from '@angular/core';
import {
  tap,
  finalize,
  takeLast,
  takeUntil
} from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import {
  Router
} from '@angular/router';

import { AuthService } from './auth.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

export const InterceptorSkipHeader = 'X-Skip-Interceptor-Header';
export const InterceptorSkipLoader = 'X-Skip-Interceptor-Loader';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  totalRequests = 0;
  isRefreshing = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router, private sharedService: SharedService, public authService: AuthService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token;
    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && this.authService.isLoggedIn && !this.isRefreshing) {
        return this.handle401Error(request, next);
      } else if (error instanceof HttpErrorResponse && error.status === 401 && this.authService.isLoggedIn && this.isRefreshing) {
        throwError(this.authService.logout());
      }
      else {
        return throwError(error);
      }
    }));
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.jwt);
          return next.handle(this.addToken(request, token.jwt));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `${token}`
      }
    });
  }
}
// SUCCESS(0,"Success" ),

//     // application business errors start from 3XXX
//     APPLICATION_BASE_ERROR(3001,"Application error"),
//     APPLICATION_INVALID_REQUEST_ERROR(3400,"Invalid request"),
//     APPLICATION_ENTITY_ALREADY_EXISTS_ERROR(3403,"Entity already exist"),
//     APPLICATION_ENTITY_NOT_FOUND_ERROR(3404,"Entity not found"),


//     // database errors start from 51XX
//     DATABASE_ERROR(5100,"Database error"),
//     DATABASE_ENTITY_ALREADY_EXISTS_ERROR(5101,"Entity already exist"),
//     DATABASE_SQL_INTEGRITY_CONSTRAINT_ERROR(5102,"SQL integrity constraint"),

//     // integration errors start from 52XX
//     INTEGRATION_ERROR(5200,"Integration error"),

//     // file system errors start from 53XX
//     FILE_SYSTEM_ERROR(5300,"File system error"),

//     // security errors start from 54XX
//     SECURITY_BAD_REQUEST(5400 ,"Bad Request"),
//     SECURITY_UNAUTHORIZED_ERROR(5401 ,"Unauthorized"),
//     SECURITY_ACCESS_DENIED_ERROR(5403 ,"Access denied"),
//     SECURITY_INSUFFICIENT_AUTHENTICATION_ERROR(5401 ,"Insufficient authen
