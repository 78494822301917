import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef, ModalOptions} from 'ngx-bootstrap/modal';

@Component({
  selector: 'tradestrat-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styles: [
  ]
})
export class ConfirmationModalComponent implements OnInit {

  @Input() headerTitle;
  @Input() modalMessage;
  @Input() data: unknown;
  // @Input() submitFunction;
  @Output() public closeModalEvent = new EventEmitter();
  @Output() public submitFunction = new EventEmitter();

  modalRef: BsModalRef;
  modalOptions: ModalOptions = {
    // backdrop: 'static',
    keyboard: false,
    class: 'modal-lg',
  };
  constructor(public activeModal: BsModalRef) { }


  ngOnInit(): void {
  }

  closeModal(): void {
     this.activeModal.hide();
  }

  onSubmit(): void {
    this.closeModal();
    this.submitFunction.emit(this.data);
  }

}
