import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  loaderSubject = new Subject();
  logoImage = '';
  loginBackground = '';
  AppTitle = '';

  constructor() { }

  showLoader(): void {
    this.loaderSubject.next({ show: true });
  }
  hideLoader(): void {
    this.loaderSubject.next({ show: false });
  }
}
