import { Injectable } from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  Router,
  CanActivateChild
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class DataUploadGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authservice: AuthService) { }

  canActivate(route, state: RouterStateSnapshot): boolean {
    if (this.authservice.checkToken()) {
      const checkRole = this.authservice.hasRoles(['data_upload']);
      if (checkRole) {
        return true;
      } else {
        this.router.navigate(['/page-not-found']);
      }
    }
    return false;
  }
  canActivateChild(route, state: RouterStateSnapshot): boolean {
    if (this.authservice.checkToken()) {
      const checkRole = this.authservice.hasRoles(['data_upload']);
      if (checkRole) {
        return true;
      } else {
        this.router.navigate(['/page-not-found']);
      }
    }
    return false;
  }
}
