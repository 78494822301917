import { Injectable } from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  Router,
  CanActivateChild
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authservice: AuthService) { }

  canActivate(route, state: RouterStateSnapshot): boolean {
    if (this.authservice.checkToken()) {
      const getRoles = localStorage.getItem('roles') ? localStorage.getItem('roles').split(',') : null;
      const checkRole = getRoles.includes('tenant_admin');
      if (checkRole) {
        return true;
      } else {
        this.router.navigate(['/page-not-found']);
      }
    }
    return false;
  }
  canActivateChild(route, state: RouterStateSnapshot): boolean {
    if (this.authservice.checkToken()) {
      const getRoles = localStorage.getItem('roles') ? localStorage.getItem('roles').split(',') : null;
      const checkRole = getRoles.includes('tenant_admin');
      if (checkRole) {
        return true;
      } else {
        this.router.navigate(['/page-not-found']);
      }
    }
    return false;
  }

}
