import {TranslateModule} from '@ngx-translate/core';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreRoutingModule} from './core-routing.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {SigninComponent} from './components/signin/signin.component';
import {SharedModule} from '../shared/shared.module';
import {AuthService} from './services/auth.service';
import {AuthGuard} from './guards/auth.guard';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {LayoutModule} from './../layout/layout.module';
import {ForgetPasswordComponent} from './components/forget-password/forget-password.component';
import {CBUGuard} from './guards/cbu.guard';
import {KAMGuard} from './guards/kam.guard';
import {HQGuard} from './guards/hq.guard';
import {AdminService} from './services/admin.service';
import {AdminGuard} from './guards/admin.guard';
import { DataUploadGuard } from './guards/data-upload.guard';


@NgModule({
  declarations: [SigninComponent, ResetPasswordComponent, ForgetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    LayoutModule,
    CoreRoutingModule,
    TranslateModule,
    TooltipModule.forRoot(),
  ],
  providers: [AuthService, AdminService, AuthGuard, CBUGuard, KAMGuard, HQGuard, AdminGuard, DataUploadGuard],
  exports: []
})
export class CoreModule {
}
