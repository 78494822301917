import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Group, Item, MultiGroupTableDto } from './multi-group-table.model';
import {
  ExcelDataModel,
  mapToExcelDataModel,
  createExcelFromData,
} from './export-to-csv.model';
@Component({
  selector: 'tradestrat-multi-group-table',
  templateUrl: './multi-group-table.component.html',
  styleUrls: ['./multi-group-table.component.scss'],
})
export class MultiGroupTableComponent implements OnInit {
  @Input() mainData: MultiGroupTableDto;
  @Input() allTableSettings: Group[];
  @Output() viewedData = new EventEmitter<any[]>();
  filters: object = {};
  private _sharedData = [];

  public set sharedData(v: any[]) {
    this._sharedData = v;
    this.viewedData.emit(this.sharedData);
  }

  public get sharedData(): any[] {
    return this._sharedData;
  }

  tablePinned: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.sharedData = [...this.mainData.items];
  }
  public getSharedData(e: Item[]): void {
    this.sharedData = [...e];
  }
  public addFilter(filterObj: object, tableGroup: string) {
    this.filters[tableGroup] = { ...filterObj };
    this.filterData();
  }
  private filterData(): void {
    const filterList = {};
    Object.keys(this.filters).forEach((tableGroupKey) => {
      Object.keys(this.filters[tableGroupKey]).forEach((FKey) => {
        filterList[FKey] = this.filters[tableGroupKey][FKey];
      });
    });
    this.sharedData = this.mainData.items.filter((item) => {
      let isAllowed: boolean = Object.keys(filterList).every((key) => {
        return item[key]
          .toLowerCase()
          .includes(filterList[key].toLowerCase());
      });

      return isAllowed;
    });
  }
  getTableGroup(title: string): Group {
    if (this.allTableSettings && this.allTableSettings.length > 0) {
      const group = this.allTableSettings.find((item) => item.title === title);
      return group;
    }
    return;
  }
}
