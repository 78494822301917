import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ActionOfTable, SimpleTableModel, TableHeaderModel} from '../../../../utils/common-models';
import {sortingCase, SortOrder, toggleSortOrder} from '../../../../utils/common-methods';
import {Observable} from 'rxjs';
import {Kam} from '../../../features/kam/kam-list/models/kam.model';
import {map} from 'rxjs/operators';
import {CommercialPolicy} from '../../../features/cbu/commercial-policy-list/models/commercial-policy.model';

@Component({
  selector: 'tradestrat-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})

export class SimpleTableComponent implements OnInit, OnChanges {
  @Input() data: { tableHeader: TableHeaderModel[], tableBody: Array<Kam | CommercialPolicy> };
  @Input() adaptedData: Array<SimpleTableModel[]>;
  @Input() actionIcons: ActionOfTable;
  @Input() multipleSelectTooltip: any[];
  @Input() filteringStatus: any;
  @Input() isLoading: boolean;
  @Input() routerLinkForItem: string;
  @Input() emptyData: { title: string, paragraph: string };
  @Output() public upload = new EventEmitter();
  @Output() public download = new EventEmitter();
  @Output() public edit = new EventEmitter();
  @Output() public duplicate = new EventEmitter();
  @Output() public delete = new EventEmitter();
  public sortOrder = SortOrder;
  tableList: Observable<Array<SimpleTableModel[]>>;
  sortingAscending;
  tableDataAdapter: any = [];

  constructor() {
  }

  ngOnChanges(): void {

    this.tableList = new Observable<Array<SimpleTableModel[]>>(observer => {
      observer.next(this.adaptedData);
    });

    if (this.filteringStatus) {
      this.applyFilteringMethod(this.filteringStatus);
    }
  }

  ngOnInit(): void {

  }

  sortBy(key, index, sortingStatus?: SortOrder | null): void {
    this.sortingAscending = toggleSortOrder(sortingStatus);

    let firstValue, secondValue;

    this.tableList = this.tableList.pipe(
      map(data => {
        data.sort((a, b) => {
          a.filter(item => item.columnId === key).map(res => firstValue = {[key]: res.value, index: res.index});
          b.filter(item => item.columnId === key).map(res => secondValue = {[key]: res.value, index: res.index});
          return sortingCase(this.sortingAscending, firstValue, secondValue, key);
        });

        return data;
      })
    );

    if (this.sortingAscending) {
      this.data.tableHeader[index].iconActiveStatus = true;
      this.data.tableHeader[index].sortingOrderStatus = this.sortingAscending;
    } else {
      this.data.tableHeader[index].iconActiveStatus = false;
      this.data.tableHeader[index].sortingOrderStatus = undefined;
    }
  }

  applyFilteringMethod(event): void {
    let columnName;

    Object.keys(event).map(key => {
      columnName = key;
    });

    this.tableList = this.tableList.pipe(
      map(element => {
        if (event[columnName]) {
          return this.adaptedData.filter(row => row.filter(item => {
            return typeof item.value === 'object' ?
              item.columnId === columnName && item.value?.name.toLowerCase().includes(event[columnName].toLowerCase())
              : item.columnId === columnName && item.value?.toLowerCase().includes(event[columnName].toLowerCase());
          }).length > 0);
        } else {
          return this.adaptedData;
        }
      })
    );
  }

  uploadEmitter(event): void {
    this.upload.emit(event);
  }

  editEmitter(event): void {
    this.edit.emit(event);
  }

  duplicateEmitter(event): void {
    this.duplicate.emit(event);
  }

  deleteEmitter(event): void {
    this.delete.emit(event);
  }

  downloadFile(event): void {
    this.download.emit(event);
  }
}
