import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TableItemDirective} from './table-item.directive';
import {TableService} from './table.service';

@Component({
  selector: 'tradestrat-colum-managment',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{'common.colMangTitle' | translate}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="columns.length">
        <ng-container *ngFor="let item of columns">
          <tradestrat-switch-button [(ngModel)]="item.visible" [ngModelOptions]="{standalone: true}" [btnType]="'squareCheckbox'" [switchBtnList]="columns" [labelText]="item.header"></tradestrat-switch-button>
        </ng-container>
      </ng-container>


    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="saveChanges()">{{"common.save" | translate}}</button>
      <button type="button" class="btn greyBtn" (click)="bsModalRef.hide()">{{"common.close" | translate}}</button>
    </div>
  `,
  styleUrls: ['./table.component.scss'],
})

export class ColumManagmentComponent implements OnInit {
  columns: Array<TableItemDirective<any>>;
  private tableService: TableService<any>;
  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit(): void {
  }

  saveChanges(): void {
    this.tableService.displayedColumnsChanges.next(this.columns);
    this.bsModalRef.hide();
  }
}
