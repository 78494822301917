import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';
import {removeElementOrRemoveChild} from '../../core/utils/operators';
import {AuthService} from '../../core/services/auth.service';

/** directive */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[permission]',
})
export class PermissionDirective implements OnInit {

  /** list of permissions that compare against user's roles */
  @Input() /** permission */
 public permission: Array<string>;

  /** permissionMode to determine whether the directive will hide the dom element or remove it */
  @Input() /** permissionMode */
 public permissionMode: string;

/** excludeList */
  @Input() /** excludeList */
 public excludeList: Array<string>;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private authenticationService: AuthService,
  ) { }

  /**
   * on initialize the directive, compare the permission access list against contract type if exists
   * if not exists, the comparsion will be against current user's access list
   * then the element will be hide if it does not match.
   */
  /** ngOnInit */
  public ngOnInit(): void {
    if (this.excludeList) {
      if (!this.authenticationService.hasRoles(this.excludeList)) {
        this.elementRef.nativeElement.hidden = false;
      } else {
        if (this.permissionMode === 'removeDomElement') {
          removeElementOrRemoveChild(this.elementRef.nativeElement);
        }
          else {
            this.elementRef.nativeElement.hidden = true;
        }
      }
    }
      else {

        if (this.authenticationService.hasRoles(this.permission)) {
          this.elementRef.nativeElement.hidden = false;
        } else {

          if (this.permissionMode === 'removeDomElement') {
            removeElementOrRemoveChild(this.elementRef.nativeElement);
          }
          else {
            this.elementRef.nativeElement.hidden = true;
          }

        }
      }
    }
  }
