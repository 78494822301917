export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export function toggleSortOrder(value: SortOrder | undefined): SortOrder | null {
  switch (value) {
    case undefined:
      return SortOrder.Ascending;
    case SortOrder.Ascending:
      return SortOrder.Descending;
    case SortOrder.Descending:
      return undefined;
    default:
      throw new Error('sort order not valid value');
  }
}

export function ascendingSorting(firstCompareValue, secondCompareValue): any {
  return firstCompareValue > secondCompareValue ? 1 : -1;
}

export function descendingSorting(firstCompareValue, secondCompareValue): any {
  return firstCompareValue < secondCompareValue ? 1 : -1;
}

export function sortingCase(status, firstCompareValue, secondCompareValue, columnKey): any {
  const hasIndexOfRaw = firstCompareValue.index || firstCompareValue.index === 0 ? true : false;

  const firstValue =
    typeof firstCompareValue[columnKey] === 'object' ?
      firstCompareValue[columnKey].name.toLowerCase() :
      firstCompareValue[columnKey]?.toLowerCase();

  const secondValue =
    typeof secondCompareValue[columnKey] === 'object' ?
      secondCompareValue[columnKey].name.toLowerCase() :
      secondCompareValue[columnKey]?.toLowerCase();

  switch (status) {
    case SortOrder.Ascending:
      return ascendingSorting(firstValue, secondValue);
      return 0;
      break;
    case SortOrder.Descending:
      return descendingSorting(firstValue, secondValue);
      return 0;
      break;
    default:
      if (hasIndexOfRaw) {
        if (firstCompareValue.index > secondCompareValue.index) {
          return 1;
        }
        if (firstCompareValue.index < secondCompareValue.index) {
          return -1;
        }
      }
      return 0;
  }
}
