import { Component, Input } from '@angular/core';

@Component({
  selector: 'tradestrat-go-back-link',
  templateUrl: 'go-back-link.component.html',
  styleUrls: ['go-back-link.component.scss'],
})
export class GoBackLinkComponent {
  @Input() public link!: string;
}
