import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';

import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { CounterComponent } from './components/counter/counter.component';
import { GoBackLinkComponent } from './components/go-back-link/go-back-link.component';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { ModalComponent } from './components/modal/modal.component';

import { AlertComponent } from './components/alert/alert.component';
import { BucketComponent } from './components/bucket/bucket.component';
import { TermComponent } from './components/term/term.component';

import {TableColumnFilterComponent} from './components/filter/table-column-filter.component';
import {TableComponent} from './components/table/table.component';
import {TableItemDirective} from './components/table/table-item.directive';
import {ColumManagmentComponent} from './components/table/table-managment.component';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {CardItemsComponent} from './components/card-items/card-items.component';
import {RouterModule} from '@angular/router';
import {TabsComponent} from './components/tabs/tabs.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {CardListComponent} from './components/card-list/card-list.component';
import {AdminModalComponent} from './components/admin-modal/admin-modal.component';
import {TextareaComponent} from './components/text-area/textarea.component';
import {NgxBootstrapMultiselectModule} from 'ngx-bootstrap-multiselect';
import {PermissionDirective} from './directives/permission.directive';
import {CustomFilterByColumnIdComponent} from './components/custom-filter-by-column-id/custom-filter-by-column-id.component';
import {MultiGroupTableComponent} from './components/multi-group-table/multi-group-table.component';
import {ToggleSelectComponent} from './components/toggle-select/toggle-select.component';
import {MultiSelectFieldComponent} from './components/multi-select-field/multi-select-field.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {DatepickerComponent} from './components/datepicker/datepicker.component';
import {BarChartComponent} from './components/bar-chart/bar-chart.component';
import {EmptyListComponent} from './components/empty-list/empty-list.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {OverrideChangesModalComponent} from './components/override-changes-modal/override-changes-modal.component';
import {TemplateDatepickerComponent} from './components/template-datepicker/template-datepicker.component';
import {SimpleTableComponent} from './components/simple-table/simple-table.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { DotsChartComponent } from './components/dots-chart/dots-chart.component';
import { TwoLevelDotChartComponent } from './components/two-level-dot-chart/two-level-dot-chart.component';
import { BubbleChartComponent } from './components/bubble-chart/bubble-chart.component';
import { BasicBarChartComponent } from './components/basic-bar-chart/basic-bar-chart.component';
import { DoubleStackBarChartComponent } from './components/double-stack-bar-chart/double-stack-bar-chart.component';
import { DoubleBarChartComponent } from './components/double-bar-chart/double-bar-chart.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CardComponent,
    CounterComponent,
    SwitchButtonComponent,
    AlertComponent,
    TableColumnFilterComponent,
    TableComponent,
    TableItemDirective,
    ColumManagmentComponent,
    ModalComponent,
    ConfirmationModalComponent,
    BucketComponent,
    TermComponent,
    AdminModalComponent,
    CardItemsComponent,
    GoBackLinkComponent,
    TabsComponent,
    CardListComponent,
    PermissionDirective,
    TextareaComponent,
    CustomFilterByColumnIdComponent,
    MultiGroupTableComponent,
    ToggleSelectComponent,
    MultiSelectFieldComponent,
    DatepickerComponent,
    BarChartComponent,
    EmptyListComponent,
    SpinnerComponent,
    SimpleTableComponent,
    OverrideChangesModalComponent,
    TemplateDatepickerComponent,
    DotsChartComponent,
    DoubleBarChartComponent,
    TwoLevelDotChartComponent,
    BubbleChartComponent,
    BasicBarChartComponent,
    DoubleStackBarChartComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule.forChild(),
    AlertModule.forRoot(),
    TabsModule.forRoot(),
    NzSelectModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NgxBootstrapMultiselectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatDatepickerModule,
    RouterModule.forChild([]),
  ],
  exports: [
    CardComponent,
    CounterComponent,
    SwitchButtonComponent,
    AlertComponent,
    ModalComponent,
    TableColumnFilterComponent,
    TableComponent,
    TableItemDirective,
    ColumManagmentComponent,
    TabsComponent,
    CardListComponent,
    AccordionModule,
    TooltipModule,
    ConfirmationModalComponent,
    AdminModalComponent,
    BucketComponent,
    TermComponent,
    CardItemsComponent,
    GoBackLinkComponent,
    PermissionDirective,
    TextareaComponent,
    CustomFilterByColumnIdComponent,
    MultiGroupTableComponent,
    ToggleSelectComponent,
    MultiSelectFieldComponent,
    DatepickerComponent,
    BarChartComponent,
    ToggleSelectComponent,
    BarChartComponent,
    EmptyListComponent,
    SpinnerComponent,
    OverrideChangesModalComponent,
    TemplateDatepickerComponent,
    SimpleTableComponent,
    DotsChartComponent,
    DoubleBarChartComponent,
    TwoLevelDotChartComponent,
    BubbleChartComponent,
    BasicBarChartComponent,
    DoubleStackBarChartComponent,
  ],
  providers: [CurrencyPipe, DatePipe],
  entryComponents: [ColumManagmentComponent, ConfirmationModalComponent],
})
export class SharedModule {}
