import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserAccountRoutingModule } from './user-account-routing.module';
import { UserAccountContainerComponent } from './user-account-container/user-account-container.component';
import { SettingsComponent } from './settings/settings.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { OrdersHistoryComponent } from './orders-history/orders-history.component';

@NgModule({
  declarations: [
    UserAccountContainerComponent,
    SettingsComponent,
    PrivacyComponent,
    OrdersHistoryComponent,
  ],
  imports: [CommonModule, UserAccountRoutingModule],
  providers: []
})
export class UserAccountModule {}
