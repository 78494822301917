import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IchildrenPaths} from '../../../core/interfaces/ISidebarItems';

@Component({
  selector: 'tradestrat-card-items',
  templateUrl: './card-items.component.html',
  styleUrls: ['./card-items.component.scss']
})
export class CardItemsComponent implements OnInit {

  @Input() data: IchildrenPaths[];
  @Input() showBtnStatus: boolean;
  @Output() public routeOuput = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  outputRouteToComponent(link): void {
    if (link) {
      this.routeOuput.emit(link);
    }
  }
}
