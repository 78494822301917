import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CustomFilterByColumnIdModel, IMultiSelectFilterLookupsItem} from './custom-filter-by-column-id-model';

@Component({
  selector: 'tradestrat-custom-filter-by-column-id',
  templateUrl: './custom-filter-by-column-id.component.html',
  styleUrls: ['./custom-filter-by-column-id.component.scss']
})
export class CustomFilterByColumnIdComponent implements OnInit, OnChanges {

  @Input() public lookupForSearch: CustomFilterByColumnIdModel[];
  @Input() public searchForKam: boolean;
  @Input() public enableButton: boolean;
  @Output() public submitSearch = new EventEmitter();
  @Output() public clearFilter = new EventEmitter();
  lookupColumnData: CustomFilterByColumnIdModel;
  lookupColumnId = '';
  filterKeywords: any;
  searchFieldValue: string;

  constructor() {
  }

  ngOnChanges(): void {
    this.lookupColumnId = this.lookupForSearch[0]?.id;

    this.setData();
  }

  ngOnInit(): void {
  }

  updateFilter(event): void {
    if (event.type === 'multi-select') {
      this.filterKeywords = [];
      this.lookupForSearch?.filter(row => row.id === this.lookupColumnId).map(item => {
        event.value.map(val => {
          item.lookupData.filter(element => element.name === val).map(row => this.filterKeywords.push(row));
        });
      });
    } else {
      this.filterKeywords = event.value;
    }

    this.submitSearchFunction();
  }

  setData(event?: any): void {
    this.lookupColumnData = null;

    setTimeout(() => {
      this.lookupForSearch?.filter(row => row.id === this.lookupColumnId).map(item => {
        this.lookupColumnData = item;
      });
    }, 100);
  }

  submitSearchFunction(): void {
    this.submitSearch.emit({
      [this.lookupColumnId]: this.filterKeywords
    });
  }

  removeFilterKeywords(): void {
    this.lookupColumnId = '';
    this.filterKeywords = undefined;
    this.lookupColumnData = null;
    this.clearFilter.emit(true);
  }

}
