import { AuthInterceptorService } from './core/services/auth-interceptor.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {UserAccountModule} from './features/user-account/user-account.module';
import {SharedModule} from './shared/shared.module';
import {LayoutModule} from './layout/layout.module';
import {ViewsModule} from './views/views.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpErrorInterceptor } from './core/services/error-handling.service';

// import {AccordionModule, ModalModule, TooltipModule} from 'ngx-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {CurrencyPipe, registerLocaleData} from '@angular/common';
import localeGermany from '@angular/common/locales/de';
import localeGermanyExtra from '@angular/common/locales/extra/de';
import localeEn from '@angular/common/locales/en';
import localeEnglishExtra from '@angular/common/locales/extra/en';
import deTranslation from '../assets/i18n/de.json';
import enTranslation from '../assets/i18n/en.json';
import {CommonViewComponent} from './shared/common-component-view/common-component-view.component';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NzTableModule } from 'ng-zorro-antd/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

const translations = {
  de: deTranslation,
  en: enTranslation
};

registerLocaleData(localeGermany, 'de-DE', localeGermanyExtra);
registerLocaleData(localeEn, 'en-EN', localeEnglishExtra);

export class CustomLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(translations[lang]);
  }
}
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

@NgModule({
    declarations: [
        AppComponent,
        CommonViewComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        UserAccountModule,
        NzTableModule,
        SharedModule,
        LayoutModule,
        ViewsModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useClass: CustomLoader, deps: [HttpClient] },
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        { provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
