import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {
  Observable,
  of,
  throwError
} from 'rxjs';
import {
  switchMap
} from 'rxjs/operators';

export class ErrorType {
  constructor(
    public code: string,
    public status: string,
    public message: string,
    public timeStamp: string
  ) {
  }
}


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService
  ) {
  }

  intercept(request: HttpRequest<ErrorType>, next: HttpHandler): Observable<any> {
    return next.handle(request)
      .pipe(
        switchMap((resp: HttpResponse<any>) => {
          if (resp instanceof HttpResponse) {
            if (resp.body && resp.body.status) {
              if (resp.body.status.code === 0) {
                return of(resp);
              } else {
                if (resp.body.status.code === 3001) {
                  this.toastr.warning(resp.body.status.message,
                    'Warning',
                    {positionClass: 'toast-top-center', titleClass: 'black-color-title', messageClass: 'black-color'});
                } else {
                  this.toastr.error(resp.body.status.message,
                    'Error occurred',
                    {positionClass: 'toast-top-center', titleClass: 'black-color-title', messageClass: 'black-color'});
                  return throwError(resp);
                }
              }
            }
          }
          return of(resp);

        })
      );
  }
}
