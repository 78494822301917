import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { ModalObject } from './modal-ref';

@Component({
  selector: 'tradestrat-modal',
  templateUrl: './modal.component.html',
  styles: []
})

export class ModalComponent implements OnInit, OnChanges {

  @Input() formObject: ModalObject[];
  @Input() headerTitle;
  @Output() public closeModalEvent = new EventEmitter();
  @Output() public submitFunction = new EventEmitter();

  form: FormGroup;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.buildFormForModal(this.formObject);
  }

  closeModal(): void {
    this.closeModalEvent.emit(true);
  }

  onSubmit(): void {
    this.closeModal();
    this.submitFunction.emit(this.form.value);
  }

  buildFormForModal(formObjectData: ModalObject[]): void {
    this.form = new FormGroup(formObjectData.reduce((acc, field) => {
      acc[field.field] = new FormControl(field.initValue ? field.initValue : '');

      if (field.validationRequired && field.validationPattern) {
        acc[field.field].setValidators([Validators.required, Validators.pattern(field.validationPattern)]);
      } else if (field.validationRequired) {
        acc[field.field].setValidators(Validators.required);
      } else if (field.validationPattern) {
        acc[field.field].setValidators(Validators.pattern(field.validationPattern));
      }

      return acc;
    }, {}));
  }
}
