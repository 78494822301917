import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {filter} from 'lodash';
import {takeWhile} from 'rxjs/operators';
import {ClearColumnFilter, ColumnFilter} from '../components/filter/table-column-filter.model';
import {filterTableData, TableService} from '../components/table/table.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ModalObject} from '../components/modal/modal-ref';

@Component({
  selector: 'tradestrat-common-component-view',
  templateUrl: './common-component-view.component.html',
  styleUrls: ['./common-component-view.component.scss'],
  providers: [TableService]

})

export class CommonViewComponent implements OnInit {
  accordionList = [
    {
      header: {
        en: 'Header 1',
        de: 'Header 1'
      },
      content: {
        en: 'Content 1',
        de: 'Content 1'
      }
    },
    {
      header: {
        en: 'Header 2',
        de: 'Header 2'
      },
      content: {
        en: 'Content 2',
        de: 'Content 2'
      }
    }
  ];

  dropdownData: { id: string; name: string; }[];
  isFilteringServerSide = false;
  // tslint:disable-next-line: max-line-length
  data = [{test: 1, test2: 1, test3: 'h17'}, {test: 1, test2: 2, test3: 'e14'}, {test: 1, test2: 3, test3: 'f15'}, {
    test: 1,
    test2: 4,
    test3: 'c12'
  }, {test: 1, test2: 5, test3: 'g16'}, {test: 1, test2: 16, test3: 'b11'}];
  tableDataSource = [...this.data];
  modalRef: BsModalRef;
  @ViewChild('addTenant') addTenantModal: TemplateRef<any>;

  tenantFormObject: ModalObject[] = [
    {
      field: 'code',
      fieldLabel: 'code',
      type: 'text',
      validationRequired: true,
      validationPattern: '^[0-9]+$'
    },
    {
      field: 'name',
      fieldLabel: 'name',
      type: 'text',
      validationRequired: true,
      validationPattern: ''
    },
    {
      field: 'description',
      fieldLabel: 'description',
      type: 'text',
      validationRequired: true,
      validationPattern: ''
    },
    {
      field: 'status',
      fieldLabel: 'status',
      type: 'text',
      validationRequired: true,
      validationPattern: ''
    }
  ];

  constructor(public tableService: TableService<any>, private modalService: BsModalService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    const children: Array<{ id: string; name: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({id: i.toString(36) + i, name: i.toString(36) + i});
    }
    this.dropdownData = children;
    this.tableService.filterChanges.pipe(takeWhile(() => !this.isFilteringServerSide)).subscribe((filterData) => {
      this.tableDataSource = filterTableData(this.data, filterData, this.tableService.getFilterType());
    });
  }

  public updateFilter(value: ColumnFilter | ClearColumnFilter): void {
    if (value.isCleared) {
      this.tableService?.removeFilter(value.key);
    } else {
      this.tableService?.addFilter(value);
    }
  }

  openModalWithClass(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, {class: 'gray modal-lg'})
    );
  }

  closeModalFromOutput(event): void {
    this.modalRef.hide();
  }

  addTenantFunction(event): void {
  }
}
