import { HeaderService } from './../../core/services/header.service';
import { HeaderPattern } from './../../core/utils/header-list';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from './../../shared/shared.service';
@Component({
  selector: 'tradestrat-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  headerItem: HeaderPattern;
  moduleHeaderItem$: Subscription = new Subscription();
  activatedTab: number;
  logoImage = '';
  AppTitle = '';
  constructor(private headerService: HeaderService, private router: Router, private sharedService: SharedService) {

  }
  ngOnInit(): void {
    this.logoImage = this.sharedService.logoImage ? this.sharedService.logoImage : './assets/images/Logos/logo.png';
    this.AppTitle = this.sharedService.AppTitle ? this.sharedService.AppTitle : 'Trade Strat';
  }
  navigatePathUrl(module: string, tabs: string, subTabs?: string): void {
    const path = subTabs ? module + '/' + tabs + '/' + subTabs : module + '/' + tabs;
    this.router.navigate([path]);
  }
  ngOnDestroy(): void {
     this.moduleHeaderItem$.unsubscribe();
    // this.moduleHeaderItem$ ? this.moduleHeaderItem$.unsubscribe() : null;
  }
}
