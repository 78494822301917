import {
    Directive,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Directive()
// Abstract components require a decorator but aren't regular components.
// tslint:disable-next-line: directive-class-suffix
export abstract class BaseFieldComponent<T>
    implements ControlValueAccessor, OnInit {
    @Input() public fieldId?: string;
    @Input() public label?: string;
    @Input() public description?: string;
    @Input() public labelStyleImportant?: boolean;

    @Input()
    public set value(value: T | null) {
        this.baseValue = value;
        this.valueSubject.next(value);
    }

    public get value(): T | null {
        return this.baseValue;
    }

    private baseValue: T | null = null;
    private readonly valueSubject = new BehaviorSubject(this.baseValue);
    public readonly valueChanges = this.valueSubject.asObservable();

    @Input() public defaultValue?: T;

    @Input()
    public set disabled(value: boolean) {
        this.isDisabled = value;
    }

    @HostBinding('class.disabled') public isDisabled = false;

    @Input()
    public set readonly(value: boolean) {
        this.isReadonly = value;
    }

    @HostBinding('class.readonly') public isReadonly = false;
    // tslint:disable-next-line:no-output-rename
    @Output() public readonly change = new EventEmitter<T>();

    private formOnChangeCallback?: (value: T) => void;
    private formOnTouchedCallback?: () => void;

    public ngOnInit(): void {
        if (this.defaultValue) {
            this.onChange(this.defaultValue);
        }
    }

    public onChange(value: T): void {
        this.value = value;
        this.change.emit(value);
        if (this.formOnChangeCallback) {
            this.formOnChangeCallback(value);
        }
    }

    public onBlur(): void {
        if (this.formOnTouchedCallback) {
            this.formOnTouchedCallback();
        }
    }

    public writeValue(value: T): void {
        this.value = value;
    }

    public registerOnChange(fn: (value: T) => void): void {
        this.formOnChangeCallback = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.formOnTouchedCallback = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
