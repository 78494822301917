import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'tradestrat-alert-component',
  templateUrl: './alert.component.html',
  styles: []
})
export class AlertComponent implements OnInit {

  @Input() type;
  @Input() msg;
  @Input() status;

  constructor() {
  }

  ngOnInit(): void {
  }

}
