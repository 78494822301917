import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Bar, BarTypes, Draw, IExpandBar } from './double-bar-chart.model';
import * as d3 from 'd3';
import * as commonData from './../../../../utils/commonData.js';
import { CurrencyPipe } from '@angular/common';
import {
  ModifiedWaterfallBar,
  waterfallBar,
} from 'src/app/features/kam/kam-details/general-financial-impact/financial-impact.service';
@Component({
  selector: 'tradestrat-double-bar-chart',
  templateUrl: './double-bar-chart.component.html',
  styleUrls: ['./double-bar-chart.component.scss'],
})
export class DoubleBarChartComponent implements OnInit, OnChanges {
  @Input() groupBars = false;
  public get data(): ModifiedWaterfallBar[] {
    return this._data;
  }
  @Input() public set data(v: ModifiedWaterfallBar[]) {
    this._data = v;
  }
  @Output() barClick: EventEmitter<IExpandBar> = new EventEmitter();
  @Input() maxVal: number;
  // viewed data

  @Input() valueSign = '';
  @Output() expandGroup: EventEmitter<any> = new EventEmitter();

  @Input() isBaseline: boolean;
  @Input() isForcast: boolean;
  // actual data
  private _data!: ModifiedWaterfallBar[];
  public viewData: ModifiedWaterfallBar[];
  private w = 0;
  private h = 700;
  private margin = { top: 48, right: 50, bottom: 120, left: 120 };
  private barDetails = {
    width: 24,
    paddingLeft: 25,
    paddingRight: 25,
    between: 8,
  };

  public get width(): number {
    return this.w - this.margin.left - this.margin.right;
  }
  public get height(): number {
    return this.h - this.margin.top - this.margin.bottom;
  }
  private barTypes: string[] = ['baseline', 'forcast'];
  private wrapper: any;
  private svg: any;
  private chart: any;
  private barArea: any;
  private x: d3.ScaleBand<string>;
  private y: any;
  private xAxis: any;
  private yAxis: any;
  private barEl: any;
  private hoverPanel: any;
  constructor(
    private container: ElementRef<HTMLDivElement>,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.wrapper = d3
      .select(this.container.nativeElement)
      .select('.double-bar-chart-wrapper');
    this._innit();
  }
  ngOnChanges(changes: SimpleChanges): void {
    const dataChange = changes.data;
    const baselineChange = changes.isBaseline;
    const ForcastChange = changes.isForcast;
    if (dataChange || baselineChange || ForcastChange) {
      if (this.isForcast && this.isBaseline)
        this.barDetails.paddingLeft = this.barDetails.paddingRight = 25;
      else this.barDetails.paddingLeft = this.barDetails.paddingRight = 42;
    }
    if (
      (dataChange && dataChange.firstChange === false) ||
      (baselineChange && baselineChange.firstChange === false) ||
      (ForcastChange && ForcastChange.firstChange === false)
    ) {
      this._innit();
      // setting width based on columns count
    }
  }
  private _innit(): void {
    this.viewData = this.mapData().map((item, i) => {
      item.identifier = 'bar_' + i;
      item.baseline.parentId = item.identifier;
      item.forcast.parentId = item.identifier;
      item.baseline.identifier = 'baseline_bar_' + i;
      item.forcast.identifier = 'forcast_bar_' + i;
      return item;
    });
    this.setWidth();
    this.initScales();
    this.initSvg();
    this.drawChart();
    this.drawAxis();
  }
  private mapData(): ModifiedWaterfallBar[] {
    if (this.groupBars) {
      const groups = [
        ...new Set(
          this._data
            .filter((item) => item.baseline.groupBy)
            .map((item) => item.baseline.groupBy)
        ),
      ];
      const mergedBars = [];
      this.barTypes.forEach((type) => {
        groups.forEach((g, index) => {
          const groupBars = this._data.filter(
            (item) => item[type].groupBy === g
          );
          if (groupBars.length > 1) {
            // merge bars
            const tempBar = new Bar();

            tempBar.type = BarTypes.GROUP_BAR;
            tempBar.id = 'bar' + index;
            tempBar.title = g;
            tempBar.color = '#E9769E';
            tempBar.actualDraw = new Draw(
              groupBars[groupBars.length - 1][type].actualDraw.from,
              groupBars[0][type].actualDraw.to
            );
            groupBars.forEach((b) => {
              tempBar.calculated += b[type].calculated;
              tempBar.value += b[type].value;
            });

            mergedBars.push({
              title: g,
              bar: tempBar,
              type,
            });
          } else {
            mergedBars.push({
              title: g,
              bar: groupBars[0],
              type,
            });
          }
        });
      });

      const result = this._data.map((item) => {
        if (item.baseline.groupBy) {
          const groupedBar = new ModifiedWaterfallBar();
          groupedBar.baseline = mergedBars
            .filter((mb) => mb.type === 'baseline')
            .find((mb) => mb.title === item.baseline.groupBy).bar;
          groupedBar.forcast = mergedBars
            .filter((mb) => mb.type === 'forcast')
            .find((mb) => mb.title === item.forcast.groupBy).bar;
          return groupedBar;
        } else {
          return item;
        }
      });
      return [...new Set(result.map((item) => item.baseline.title))].map(
        (title) => {
          return result.find((item) => item.baseline.title === title);
        }
      );
    } else {
      return this._data;
    }
  }
  private setWidth(): void {
    let barWidth: number;
    //double bars
    if (this.isForcast && this.isBaseline)
      barWidth =
        this.barDetails.width * 2 +
        this.barDetails.between +
        this.barDetails.paddingLeft +
        this.barDetails.paddingRight;
    //single bar
    else
      barWidth =
        this.barDetails.width +
        this.barDetails.paddingLeft +
        this.barDetails.paddingRight;

    //calculate width
    this.w =
      this.viewData.length * barWidth + this.margin.left + this.margin.right;
  }
  private initScales(): void {
    this.x = d3.scaleBand().rangeRound([0, this.width]);

    this.y = d3.scaleLinear().range([this.height, 0]);
  }
  private initSvg(): void {
    this.wrapper.select('svg').remove();
    this.svg = this.wrapper
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr('viewBox', '0 0 ' + this.w + ' ' + this.h);

    this.chart = this.svg
      .append('g')
      .classed('chart-contents', true)
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );
    this.barArea = this.chart.append('g').classed('layers', true);
  }
  private drawAxis(): void {
    this.xAxis = this.chart
      .insert('g', ':first-child')
      .classed('x axis', true)
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(
        d3.axisBottom(this.x).tickPadding(20).tickSizeInner(0).tickSizeOuter(0)
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick')
          .select('line')
          .attr('stroke', '#E3E3E6')
          .attr('y2', (d: string) => {
            const currentEl = this.viewData.find(
              (item) => item.baseline.title === d
            );
            return currentEl.baseline.actualDraw.from > 0
              ? -(this.height - this.y(currentEl.baseline.actualDraw.from))
              : 0;
          })
      )
      .call((g) => g.selectAll('.tick').select('text').remove())
      .call((g) =>
        g
          .selectAll('.tick')
          .append('foreignObject')
          .attr('width', this.x.bandwidth())
          .attr('id', (d: string) => d)
          .attr('height', 19 * 5)
          .attr('x', -this.x.bandwidth() / 2)
          .attr('y', 16)
          .append('xhtml:div')
          .style('text-align', 'center')

          .style('font-family', 'SimonKucher, sans-serif')
          .style('font-size', '16px')
          .style('line-height', '19px')
          .style('padding', '0 5px')
          .html((d: string) => {
            return d;
          })
      );

    this.yAxis = this.chart
      .insert('g', ':first-child')
      .classed('y axis', true)
      .call(
        d3
          .axisLeft(this.y)
          .ticks(8)
          .tickSizeInner(
            this.wrapper.node().offsetWidth
              ? -this.wrapper.node().offsetWidth
              : -this.width
          )
          .tickPadding(15)
          .tickSizeOuter(0)

          .tickFormat((d: any, i) => {
            const currentCurrency = localStorage.getItem(
              commonData.localCurrency
            );
            const pipedVal = this.currencyPipe.transform(
              d,
              currentCurrency,
              '',
              '0.0-0',
              currentCurrency === 'USD' ? 'en-EN' : 'de-DE'
            );
            return pipedVal + ' ' + this.valueSign;
          })
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g.selectAll('.tick').select('line').attr('stroke', '#E3E3E6')
      )
      .call((g) =>
        g
          .selectAll('.tick')
          .select('text')
          .style('font-size', '16px')
          .style('font-family', 'SimonKucher, sans-serif')
      );
  }
  private drawChart(): void {
    this.x.domain(
      this.viewData.map((d) => {
        return d.baseline.title;
      })
    );

    this.y.domain([
      0,
      this.valueSign === '%' ? 100 : this.maxVal + this.maxVal * 0.1,
    ]);
    this.barEl = this.barArea
      .selectAll('.bar-el')
      .data(this.viewData)
      .enter()
      .append('g')
      .classed('bar-el', true)
      .attr('id', (d: ModifiedWaterfallBar) => {
        return d.identifier;
      });
    this._drawBar();
  }
  private _drawBar(): void {
    const bar = this.barEl
      .selectAll('.bar')
      .data((d: ModifiedWaterfallBar) => {
        let list: Bar[] = [];
        if (this.isBaseline) list.push(d.baseline);
        if (this.isForcast) list.push(d.forcast);
        return list;
      })
      .enter()
      .append('g')
      .classed('bar', true)
      .attr('id', (d: Bar) => {
        return d.identifier;
      })
      .style('cursor', (d: Bar) => {
        return d.type === BarTypes.GROUP_BAR ? 'pointer' : 'default';
      })
      .on('click', (e: MouseEvent, d: Bar) => {
        if (d.type === BarTypes.GROUP_BAR) {
          const barIndex = this.viewData.findIndex(
            (bar) => bar.identifier === d.parentId
          );
          this.barClick.emit({
            from: this.viewData[barIndex - 1].baseline.id,
            to: this.viewData[barIndex + 1].baseline.id,
            title: d.title,
          });
        }
      })
      .on('mouseenter', (e: MouseEvent, d: Bar) => {
        // indexing element to show above others .
        this._reorderElTop(d.parentId, '.layers', '.bar-el');
        this._reorderElTop(d.identifier, `#${d.parentId}`, '.bar');
        // drawing hover panel
        this._drawOnHoverPanel(d);
      })
      .on('mouseleave', (e, d: Bar) => {
        this.barArea
          .select(`#${d.identifier}`)
          .selectAll('.panel-wrapper')
          .remove();
      });

    bar
      .append('rect')
      .style('fill', (d: Bar) => {
        if (
          d.identifier.includes('forcast') &&
          this.isForcast &&
          this.isBaseline
        )
          return '#B3B3BB';
        return d.color;
      })
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('y', (d: Bar) => {
        return this.y(d.actualDraw.to);
      })
      .attr('x', (d: Bar) => this.getBarAxisX(d))
      .attr('width', this.barDetails.width)
      .attr('height', (d: Bar) => {
        return Math.abs(this.y(d.actualDraw.to) - this.y(d.actualDraw.from));
      });
    bar
      .append('rect')
      .style('fill', 'transparent')
      .style('stroke-width', 2)
      .style('stroke', '#FF5982')
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('y', (d: Bar) => {
        if (d.expectedDraw && (d.expectedDraw.from || d.expectedDraw.to)) {
          return this.y(d.expectedDraw.to);
        }
        return 0;
      })
      .attr('x', (d: Bar) => this.getBarAxisX(d))
      .attr('width', this.barDetails.width)
      .attr('height', (d: Bar) => {
        if (
          d.identifier.includes('baseline') &&
          d.expectedDraw &&
          (d.expectedDraw.from || d.expectedDraw.to)
        ) {
          return Math.abs(
            this.y(d.expectedDraw.to) - this.y(d.expectedDraw.from)
          );
        }
        return 0;
      });
  }
  private _decimalPipe(val: number): string {
    val = Number(val);
    let pipedVal = Math.round(val);
    const currentCurrency = localStorage.getItem(commonData.localCurrency);
    return this.currencyPipe.transform(
      pipedVal,
      currentCurrency,
      '',
      '0.0-0',
      currentCurrency === 'USD' ? 'en-EN' : 'de-DE'
    );
  }
  private _reorderElTop(
    elId: string,
    parentId: string,
    identifier: string
  ): void {
    const el = this.svg.select(parentId).selectAll(identifier);
    el.sort((a: ModifiedWaterfallBar | Bar, b: ModifiedWaterfallBar | Bar) => {
      // select the parent and sort the path's
      if (a.identifier !== elId) {
        return -1;
      }
      // a is not the hovered element, send "a" to the back
      else {
        return 1;
      } // a is the hovered element, bring "a" to the front
    });
  }
  private _drawOnHoverPanel(d: Bar): void {
    const wrapperWidth = this.wrapper.node().getBoundingClientRect().width;
    const currentPlace =
      this.isBaseline && this.isForcast
        ? this.x(d.title) +
          this.barDetails.paddingLeft +
          this.barDetails.width +
          this.barDetails.between
        : this.x(d.title) + this.barDetails.paddingLeft;
    const allowedSpace = wrapperWidth - currentPlace;
    this._drawPanel(d, allowedSpace, d.type === BarTypes.GROUP_BAR);
  }
  private _drawPanel(d: Bar, allowedSpace: number, isgroup: boolean): void {
    const paddingSize = 45;
    const panelWidth = this._getCalculatedPanelWidth(d, isgroup, paddingSize);
    const minPanelHeight = d.value ? 180 : 60;
    const isInverted = allowedSpace > panelWidth ? false : true;
    const panel = this.barArea
      .select(`#${d.identifier}`)
      .insert('foreignObject', ':nth-child(1)')
      .classed('panel-wrapper', true)
      .attr('y', (d: Bar) => {
        if (isgroup) {
          return 0;
        }
        if (d.expectedDraw && (d.expectedDraw.from || d.expectedDraw.to)) {
          return d.identifier.includes('baseline')
            ? this.y(Math.max(d.expectedDraw.to, d.actualDraw.to)) - 8
            : this.y(d.actualDraw.to) - 8;
        }

        return this.y(d.actualDraw.to) - 8;
      })
      .attr('x', (d: Bar) => {
        const firstBarPos = this.x(d.title) + this.barDetails.paddingLeft;
        const secondBarPos =
          firstBarPos + this.barDetails.between + this.barDetails.width;
        const xPos =
          this.isBaseline && this.isForcast
            ? d.identifier.includes('forcast')
              ? secondBarPos
              : firstBarPos
            : firstBarPos;
        return (
          (isInverted ? xPos + paddingSize / 2 - panelWidth : xPos) +
          (isInverted ? 8 : -8)
        );
      })
      .style('height', (d: Bar) => {
        if (isgroup) {
          return 455;
        }
        let calculatedHeight = 0;
        if (d.expectedDraw && (d.expectedDraw.from || d.expectedDraw.to)) {
          if (d.identifier.includes('baseline'))
            calculatedHeight =
              Math.max(
                Math.abs(
                  this.y(d.expectedDraw.to) - this.y(d.expectedDraw.from)
                ),
                Math.abs(this.y(d.actualDraw.to) - this.y(d.actualDraw.from))
              ) + 16;
          else
            calculatedHeight =
              Math.abs(this.y(d.actualDraw.to) - this.y(d.actualDraw.from)) +
              16;
        } else {
          calculatedHeight =
            Math.abs(this.y(d.actualDraw.to) - this.y(d.actualDraw.from)) + 16;
        }
        return calculatedHeight < minPanelHeight
          ? minPanelHeight
          : calculatedHeight;
      })
      .style('width', panelWidth)
      .style(`padding-${isInverted ? 'right' : 'left'}`, paddingSize)
      .style(`padding-${!isInverted ? 'right' : 'left'}`, 8)
      .style('background', '#ECECEE')
      .style('border-radius', '8px');

    const panelContent = panel
      .append('xhtml:div')
      .classed('panel', true)
      .html((d: Bar) => {
        let content = ``;
        let valueType = d.identifier.split('_')[0];
        if (isgroup) {
          const bars = this._getGroupedBars(d);
          const total = bars.reduce((acc, bar) => {
            return bar[valueType].calculated + acc;
          }, 0);
          let iteration = ``;
          bars.forEach((b) => {
            iteration += `
          <div class="col group" style="min-width: 170px">
            <div class="label">${b[valueType].title}</div>
            <div>${this._decimalPipe(b[valueType].calculated)}</div>
          </div>`;
          });

          content = `
          <div style="margin-bottom:12px">
              <div class="label" style="font-size:16px">Total</div>
              <div class="val"  style="font-size:16px">${this._decimalPipe(
                total
              )}</div>
          </div>
          <div class="row">
          ${iteration}
          </div>

          `;
        } else {
          if (d.value && d.identifier.includes('baseline')) {
            content = `
            <div class="d-flex flex-column">
              <div class="group">
                <div class="label">Value calculated</div>
                <div class="val">${this._decimalPipe(d.calculated)}</div>
              </div>

              <div class="group">
                <div class="label">Value from P&L</div>
                <div>${this._decimalPipe(d.value)}</div>
              </div>
              <div class="group">
                <div class="label">Mismatch</div>
                <div class="val">${this._decimalPipe(
                  d.calculated - d.value
                )}</div>
              </div>
            </div>

            `;
          } else {
            {
              content = `
              <div class="d-flex flex-column">
                <div class="group">
                  <div class="label">Value calculated</div>
                  <div class="val">${this._decimalPipe(d.calculated)}</div>
                </div>
              </div>
                `;
            }
          }
        }
        return content;
      });
  }
  private _getCalculatedPanelWidth(
    d: Bar,
    isgroup: boolean,
    paddingSize: number
  ): number {
    // single bar
    if (!isgroup) {
      return 110 + paddingSize;
    }
    // grouped bar
    const groupedBarsLength = this._getGroupedBars(d).length;
    const columnConfig = {
      count: 7,
      width: 200,
    };
    return (
      Math.ceil(groupedBarsLength / columnConfig.count) * columnConfig.width +
      paddingSize
    );
  }

  private _getGroupedBars(d: Bar): ModifiedWaterfallBar[] {
    if (d.type !== BarTypes.GROUP_BAR) {
      return [];
    }
    const barIndex = this.viewData.findIndex(
      (bar) => bar.identifier === d.parentId
    );
    const range = {
      from: this.viewData[barIndex - 1].baseline.id,
      to: this.viewData[barIndex + 1].baseline.id,
    };
    const indexFrom = this._data.findIndex((i) => i.baseline.id === range.from);
    const indexTo = this._data.findIndex((i) => i.baseline.id === range.to);
    return this._data.slice(indexFrom + 1, indexTo);
  }
  private getBarAxisX(d: Bar): number {
    // if only one checked
    if (!(this.isBaseline && this.isForcast))
      return this.x(d.title) + this.barDetails.paddingLeft;
    //if both checked
    if (d.identifier.includes('baseline'))
      return this.x(d.title) + this.barDetails.paddingLeft;
    if (d.identifier.includes('forcast')) {
      return (
        this.x(d.title) +
        this.barDetails.paddingLeft +
        this.barDetails.between +
        this.barDetails.width
      );
    }
  }
}
