import {TranslateModule} from '@ngx-translate/core';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {HomepageComponent} from './homepage/homepage.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';

@NgModule({
  declarations: [FooterComponent, HeaderComponent, SidebarComponent, HomepageComponent, MainLayoutComponent],
  imports: [CommonModule, TranslateModule, SharedModule, RouterModule.forChild([]), TooltipModule.forRoot(), NzBreadCrumbModule],
  exports: [FooterComponent, HeaderComponent, SidebarComponent, HomepageComponent, MainLayoutComponent, TooltipModule]
})
export class LayoutModule {
}
