import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [CommonModule, TranslateModule, SharedModule],
  exports: [PageNotFoundComponent]
})

export class ViewsModule { }
