import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {map, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatOption} from '@angular/material/core';

@Component({
  selector: 'tradestrat-multi-select-field',
  templateUrl: './multi-select-field.component.html',
  styles: [],
})
export class MultiSelectFieldComponent implements OnInit, OnChanges {
  @ViewChild('search') searchTextBox: ElementRef;
  @Input() field: FormControl;
  @Input() label: string;
  @Input() list: any[];
  @Input() changingInputsStatus: boolean;
  @Input() config: config = {listIdKey: 'name', listViewKey: 'name'};
  @Input() selectAll: boolean = true;
  selectedValues = [];
  searchTextBoxControl = new FormControl();
  listOptions: Observable<any[]>;
  filteredOptions: Observable<any[]>;
  filteredOptionsList: any[];
  @ViewChild('allSelected') private allSelected: MatOption;

  constructor() {
  }

  ngOnChanges(): void {
    this.setList();

    if (this.changingInputsStatus) {
      this.selectedValues = [];
    }

    if (this.field.value?.length === this.list?.length) {
      this.field.patchValue([...this.field.value, 0]);
    }
  }

  ngOnInit(): void {
    this.filteredOptions.subscribe((options) => {
      this.filteredOptionsList = options;
    });
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    const filteredList = this.list?.filter(
      (option) =>
        option[this.config.listViewKey].toLowerCase().indexOf(filterValue) === 0
    );
    return filteredList;
  }

  getViewValueFromID(id: string) {
    const item = this.list?.find((item) => item[this.config.listIdKey] === id);

    return item ? item[this.config.listViewKey] : '';
  }

  setList(): void {
    this.filteredOptions = this.searchTextBoxControl.valueChanges.pipe(
      startWith<string>(''),
      map((name) => this._filter(name))
    );
  }

  openedChange(e): void {
    // Set search textbox value as empty while opening selectbox
    this.searchTextBoxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e === true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event): void {
    event.stopPropagation();
    this.searchTextBoxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state
   */
  setSelectedValues(): void {
    if (this.field.value && this.field.value.length > 0) {
      this.field.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) === -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  tosslePerOne(all) {
    if (!this.selectAll) {
      return;
    }
    if (this.allSelected.selected) {
      this.allSelected.deselect();
    }
    if (this.field.value.length == this.filteredOptionsList.length) {
      this.allSelected.select();
    }
  }

  toggleAllSelection() {
    if (!this.selectAll) {
      return;
    }
    if (this.allSelected.selected) {
      this.field.patchValue([
        ...this.filteredOptionsList.map((item) => item[this.config.listIdKey]),
        0,
      ]);
    } else {
      this.field.patchValue([]);
    }
  }
}

export interface config {
  listViewKey: string;
  listIdKey: string;
}
