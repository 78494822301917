import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'tradestrat-override-changes-modal',
  templateUrl: './override-changes-modal.component.html',
  styles: [],
})
export class OverrideChangesModalComponent implements OnInit {
  @Input() headerTitle;
  @Input() modalMessage;
  @Input() submitBtnTitle:string;
  // @Input() submitFunction;
  @Output() public closeModalEvent = new EventEmitter();
  @Output() public submitFunction = new EventEmitter<boolean>();

  modalRef: BsModalRef;
  modalOptions: ModalOptions = {
    keyboard: false,
    class: 'modal-lg',
    backdrop: 'static',
  };
  constructor(public activeModal: BsModalRef) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.activeModal.hide();
  }

  onOverride(save: boolean): void {
    this.closeModal();
    this.submitFunction.emit(save);
  }
}
