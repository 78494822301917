import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../../core/services/auth.service';

@Component({
  selector: 'tradestrat-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: [
    './page-not-found.component.scss'
  ]
})
export class PageNotFoundComponent implements OnInit {

  constructor(public authService: AuthService, private router: Router) { }

  ngOnInit(): void {

  }
  navigateByUrl(url): void {
    this.router.navigate([url]);
  }
}
