import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { GlossaryBucket } from 'src/app/features/glossary/models/glossary-bucket.model';
@Component({
  selector: 'tradestrat-bucket',
  templateUrl: './bucket.component.html',
  styleUrls: [ './bucket.component.scss']
})
export class BucketComponent implements OnInit {

  public activeBucketId: string;
  @Input() buckets: GlossaryBucket[];
  @Input() hideEdit: boolean;
  @Input() countForTermsStatus: boolean;
  @Input() termBucketCount: number;

  @Output() public selectChanges = new EventEmitter();
  @Output() public deleteChanges = new EventEmitter();
  @Output() public editChanges = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }
  delete(item: GlossaryBucket ): void{
    this.deleteChanges.emit(item);
  }
  select(item: GlossaryBucket): void{
    this.selectChanges.emit(item);
    this.activeBucketId = item.id;
  }
  edit(item: GlossaryBucket ): void{
    this.editChanges.emit(item);
  }
}
