import { Injectable } from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authservice: AuthService) { }

  canActivate(route, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (state.url === '/login' || state.url === '/forget-password') {
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('roles');
        resolve(true);
      } else if (this.authservice.checkToken()) {
         resolve(true);
      } else {
        this.router.navigate(['/login']);
        reject(false);
      }
    });


  }
}
