import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { BasicBar, IBarDetails } from './basic-bar-chart.model';
import * as d3 from 'd3';
import * as commonData from './../../../../utils/commonData.js';
@Component({
  selector: 'tradestrat-basic-bar-chart',
  templateUrl: './basic-bar-chart.component.html',
  styleUrls: ['./basic-bar-chart.component.scss'],
})
export class BasicBarChartComponent implements OnInit {
  public get data(): BasicBar[] {
    return this._data;
  }
  @Input() maxVal: number = 100;
  @Input() public set data(v: BasicBar[]) {
    this._data = v;
    this.wrapper = d3
      .select(this.container.nativeElement)
      .select('.bar-chart-wrapper');
    this._innit();
  }

  @Input() valueSign = '%';
  private _data!: BasicBar[];
  private w = 0;
  private h = 600;
  private margin = { top: 0, right: 50, bottom: 50, left: 80 };
  @Input() barDetails: IBarDetails = {
    width: 24,
    paddingLeft: 42,
    paddingRight: 42,
  };

  public get width(): number {
    return this.w - this.margin.left - this.margin.right;
  }
  public get height(): number {
    return this.h - this.margin.top - this.margin.bottom;
  }
  private wrapper: any;
  private svg: any;
  private chart: any;
  private barArea: any;
  private x: d3.ScaleBand<string>;
  private y: any;
  private xAxis: any;
  private yAxis: any;
  private barEl: any;
  private hoverPanel: any;
  constructor(private container: ElementRef<HTMLDivElement>) {}
  ngOnInit(): void {}
  private _innit(): void {
    this.setWidth();
    this.initScales();
    this.initSvg();
    this.drawChart();
    this.drawAxis();
  }
  private setWidth(): void {
    this.w =
      this._data.length *
        (this.barDetails.width +
          this.barDetails.paddingLeft +
          this.barDetails.paddingRight) +
      this.margin.left +
      this.margin.right;
  }
  private initScales(): void {
    this.x = d3.scaleBand().rangeRound([0, this.width]);

    this.y = d3.scaleLinear().range([this.height, 0]);
  }
  private initSvg(): void {
    this.wrapper.select('svg').remove();
    this.svg = this.wrapper
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr('viewBox', '0 0 ' + this.w + ' ' + this.h);

    this.chart = this.svg
      .append('g')
      .classed('chart-contents', true)
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );
    this.barArea = this.chart.append('g').classed('layers', true);
  }
  private drawAxis(): void {
    this.xAxis = this.chart
      .insert('g', ':first-child')
      .classed('x axis', true)
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(
        d3.axisBottom(this.x).tickPadding(20).tickSizeInner(0).tickSizeOuter(0)
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g.selectAll('.tick').select('line').attr('stroke', '#E3E3E6')
      )
      .call((g) => g.selectAll('.tick').select('text').remove())
      .call((g) =>
        g
          .selectAll('.tick')
          .append('foreignObject')
          .attr(
            'width',
           this.barDetails.width+ this.barDetails.paddingLeft + this.barDetails.paddingRight
          )
          .attr('id', (d: string) => d)
          .attr('height', 19 * 3)
          .attr(
            'x',
            -( this.barDetails.width+ this.barDetails.paddingLeft + this.barDetails.paddingRight) / 2
          )
          .attr('y', 16)
          .append('xhtml:div')
          .style('text-align', 'center')

          .style('font-family', 'SimonKucher, sans-serif')
          .style('font-size', '16px')
          .style('line-height', '19px')
          .html((d: string) => {
            return this._data.find(item=>item.id == d).title;
          })
      );

    this.yAxis = this.chart
      .insert('g', ':first-child')
      .classed('y axis', true)
      .call(
        d3
          .axisLeft(this.y)
          .ticks(8)
          .tickSizeInner(-this.width)
          .tickPadding(15)
          .tickSizeOuter(0)

          .tickFormat((d: any, i) => {
            return d + ' ' + this.valueSign;
          })
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g.selectAll('.tick').select('line').attr('stroke', '#E3E3E6')
      )
      .call((g) =>
        g
          .selectAll('.tick')
          .select('text')
          .style('font-size', '16px')
          .style('font-family', 'SimonKucher, sans-serif')
      );
  }
  private drawChart(): void {
    this.x.domain(
      this._data.map((d) => {
        return d.id;
      })
    );
    let maxYDomain: number = this.maxVal + this.maxVal * 0.1;

    this.y.domain([0, maxYDomain]);
    this.barEl = this.barArea
      .selectAll('.bar')
      .data(this._data)
      .enter()
      .append('g')
      .classed('bar', true)
      .attr('id', (d: BasicBar) => d.id)
      .on('mouseover', (e: MouseEvent, d: BasicBar) => {
        // indexing element to show above others .
        this._reorderElTop(d);
        // drawing hover panel
        // this._drawOnHoverPanel(d);
      })
      .on('mouseleave', (e, d: BasicBar) => {
        this.barArea.select(`#${d.id}`).selectAll('.panel-wrapper').remove();
      });

    this.barEl
      .append('rect')
      .style('fill', (d: BasicBar) => {
        return d.color;
      })
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('y', (d: BasicBar) => {
        return this.y(d.value);
      })
      .attr('x', (d: BasicBar) => {
        return this.x(d.id) + this.barDetails.paddingLeft;
      })
      .attr('width', this.barDetails.width)
      .attr('height', (d: BasicBar) => {
        return this.height - this.y(d.value);
      });

    this.barEl
      .insert('foreignObject', ':first-child')
      .classed('bar-text', true)
      .attr('y', (d: BasicBar) => {
        return this.y(d.value) - 27;
      })
      .attr('x', (d: BasicBar) => {
        return this.x(d.id);
      })
      .attr('height', 19)
      .attr(
        'width',
        this.barDetails.width +
          this.barDetails.paddingLeft +
          this.barDetails.paddingRight
      )
      .append('xhtml:div')
      .style('text-align', 'center')
      .style('font-weight', 'bold')
      .style('font-size', '16px')
      .style('font-family', 'SimonKucher, sans-serif')
      .html((d: BasicBar) => {
        return this._decimalPipe(d.value);
      });
  }
  private _decimalPipe(val: number): string {
    val = Number(val);
    let pipedVal = val && val % 1 !== 0 ? Number(val.toFixed(2)) : val;
    const currentCurrency = localStorage.getItem(commonData.localCurrency);
    switch (currentCurrency) {
      case 'EUR':
        return pipedVal.toString().replace(/[,.]/gi, ',');
      case 'USD':
      default:
        return pipedVal.toString().replace(/[,.]/gi, '.');
    }
  }
  private _reorderElTop(d: BasicBar): void {
    this.svg
      .select('.layers')
      .selectAll('.bar')
      .sort((a: BasicBar, b: BasicBar) => {
        // select the parent and sort the path's
        if (a.id !== d.id) {
          return -1;
        }
        // a is not the hovered element, send "a" to the back
        else {
          return 1;
        } // a is the hovered element, bring "a" to the front
      });
  }
}
