import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  ColumnFilter,
  TableColumnFilterType,
} from '../filter/table-column-filter.model';
import { TableItemDirective } from './table-item.directive';
import { SortOrder } from './table.component';

@Injectable()
export class TableService<T> {
  private readonly columnFiltersTypes = new Map<string, any>();
  private readonly columnFilters = new Map<string, any>();
  private readonly columnSort = new Map<string, any>();
  public readonly filterChanges = new ReplaySubject<object>(null);
  public readonly sortChanges = new ReplaySubject<object>(null);
  public readonly latestSortValueChanges = new ReplaySubject<string>(null);
  public readonly displayedColumnsChanges = new BehaviorSubject<
    Array<TableItemDirective<T>>
  >(null);

  public sortValueOrderList = [];
  public addSort(
    tableItem: TableItemDirective<unknown>,
    sortValue: SortOrder
  ): void {
    if (sortValue) {
      this.columnSort.set(tableItem.sortOn, sortValue);
      this.sortValueOrderList.push(tableItem.sortOn);
      this.latestSortValueChanges.next(
        this.sortValueOrderList[this.sortValueOrderList.length - 1]
      );
    } else {
      this.columnSort.delete(tableItem.sortOn);
      this.sortValueOrderList = this.sortValueOrderList.filter(
        (sortOnValue) => sortOnValue !== tableItem.sortOn
      );
      this.latestSortValueChanges.next(
        this.sortValueOrderList[this.sortValueOrderList.length - 1]
      );
    }
    this.sortChanges.next(this.getSortObject());
  }
  public getSort(tableItem: TableItemDirective<unknown>): SortOrder {
    return this.columnSort.get(tableItem.sortOn);
  }
  public addFilter(filter: ColumnFilter): void {
    this.columnFilters.set(filter.key, filter.value);
    this.columnFiltersTypes.set(filter.key, filter.type);
    this.filterChanges.next(this.getFilterObject());
  }

  public removeFilter(key: string): void {
    this.columnFilters.delete(key);
    this.columnFiltersTypes.delete(key);
    this.filterChanges.next(this.getFilterObject());
  }

  public clearFilters(): void {
    this.columnFilters.clear();
    this.columnFiltersTypes.clear();
    this.filterChanges.next(this.getFilterObject());
  }

  public getFilterObject(): object {
    return this.transformMapToObject(this.columnFilters);
  }
  public getFilterType(): Map<string, any> {
    return this.columnFiltersTypes;
  }
  public getSortObject(): object {
    return this.transformMapToObject(this.columnSort);
  }
  public transformMapToObject(mapObj: Map<string, any>): object {
    const allFilterObj = Array.from(mapObj).map(
      ([key, value]) => ({ key, value }),
      {}
    );
    const result = allFilterObj.reduce((r, o) => {
      const path = o.key.split('.');
      const last = path.pop();

      path.reduce((p, k) => {
        return (p[k] = p[k] || {});
      }, r)[last] = o.value;
      return r;
    }, {});
    return result;
  }
}
export function filterTableData(
  data: Array<any>,
  filterObj: object,
  filterTypes: Map<string, any>
): Array<any> {
  return data.filter((item) => {
    let isItemMatched = true;
    for (const filterKey of Object.keys(filterObj)) {
      if (filterTypes.get(filterKey) === TableColumnFilterType.Number) {
        isItemMatched = item[filterKey] === filterObj[filterKey];
      }
      if (filterTypes.get(filterKey) === TableColumnFilterType.MultiSelect) {
        isItemMatched = filterObj[filterKey].indexOf(item[filterKey]) !== -1;
      }
      if (filterTypes.get(filterKey) === TableColumnFilterType.Text) {
        isItemMatched =
          item[filterKey]
            .toString()
            .toLowerCase()
            .indexOf(filterObj[filterKey].toLowerCase()) !== -1;
      }
      if (!isItemMatched) {
        break;
      }
    }
    return isItemMatched;
  });
}
