import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'tradestrat-toggle-select',
  templateUrl: './toggle-select.component.html',
  styleUrls: ['./toggle-select.style.scss'],
})
export class ToggleSelectComponent implements OnInit {
  @Input() truthyTitle: string;
  @Input() falsyTitle: string;
  @Input() nullTitle: string;
  @Input() hoverBoxColor: string;
  @Input() isDisabled: boolean = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('true') trueEl: ElementRef;
  @ViewChild('false') falseEl: ElementRef;
  @ViewChild('null') nullEl: ElementRef;

  private _hoverBoxWidth: number;

  public get hoverBoxWidth(): number {
    if (this.trueEl) {
      if (this.value === true) {
        this._hoverBoxWidth = (<HTMLDivElement>(
          this.trueEl.nativeElement
        )).clientWidth;
      }
      if (this.value === false) {
        this._hoverBoxWidth = (<HTMLDivElement>(
          this.falseEl.nativeElement
        )).clientWidth;
      }
      if (this.value === null) {
        this._hoverBoxWidth = (<HTMLDivElement>(
          this.nullEl.nativeElement
        )).clientWidth;
      }
    }
    return this._hoverBoxWidth;
  }

  private _hoverBoxPos: number = 0;

  public get hoverBoxPos(): number {
    if (this.trueEl) {
      let paddingVal = 0;
      if (this.value === false) {
        this._hoverBoxPos = paddingVal + 0;
      }
      if (this.value === null) {
        this._hoverBoxPos =
          paddingVal + (<HTMLDivElement>this.falseEl.nativeElement).clientWidth;
      }
      if (this.value === true) {
        if (this.nullTitle) {
          // has null button
          this._hoverBoxPos =
            paddingVal +
            (<HTMLDivElement>this.falseEl.nativeElement).clientWidth +
            (<HTMLDivElement>this.nullEl.nativeElement).clientWidth;
        } else {
          this._hoverBoxPos =
            paddingVal +
            (<HTMLDivElement>this.falseEl.nativeElement).clientWidth;
        }
      }
    }
    return this._hoverBoxPos;
  }

  @Input() public set value(v: boolean) {
    this._value = v;
  }

  public get value(): boolean {
    return this._value;
  }

  private _value: boolean;

  constructor() {}

  ngOnInit(): void {}

  changeVal(v: boolean): void {
    if (this.isDisabled) return;
    if (this.value != v) {
      this.value = v;
      this.change.emit(this.value);
    }
  }
}
