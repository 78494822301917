import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Bar, BarTypes, Draw, IExpandBar } from './bar-chart.model';
import * as d3 from 'd3';
import * as commonData from './../../../../utils/commonData.js';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'tradestrat-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() groupBars = false;
  public get data(): Bar[] {
    return this._data;
  }
  @Input() public set data(v: Bar[]) {
    this._data = v;
  }
  @Output() barClick: EventEmitter<IExpandBar> = new EventEmitter();
  @Input() maxVal: number;
  // viewed data

  @Input() valueSign = '';
  @Output() expandGroup: EventEmitter<any> = new EventEmitter();
  // actual data
  private _data!: Bar[];
  public viewData: Bar[];
  private w = 0;
  private h = 600;
  private margin = { top: 48, right: 50, bottom: 100, left: 120 };
  private barDetails = {
    width: 24,
    paddingLeft: 42,
    paddingRight: 42,
  };

  public get width(): number {
    return this.w - this.margin.left - this.margin.right;
  }
  public get height(): number {
    return this.h - this.margin.top - this.margin.bottom;
  }
  private wrapper: any;
  private svg: any;
  private chart: any;
  private barArea: any;
  private x: d3.ScaleBand<string>;
  private y: any;
  private xAxis: any;
  private yAxis: any;
  private barEl: any;
  private hoverPanel: any;
  constructor(
    private container: ElementRef<HTMLDivElement>,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.wrapper = d3
      .select(this.container.nativeElement)
      .select('.bar-chart-wrapper');
    this._innit();
  }
  ngOnChanges(changes: SimpleChanges): void {
    const dataChange = changes.data;
    if (dataChange && dataChange.firstChange === false) {
      this._innit();
      // setting width based on columns count
    }
  }
  private _innit(): void {
    this.viewData = this.mapData().map((item, i) => {
      item.identifier = 'bar_' + i;
      return item;
    });
    this.setWidth();
    this.initScales();
    this.initSvg();
    this.drawChart();
    this.drawAxis();
  }
  private mapData(): Bar[] {
    if (this.groupBars) {
      const groups = [
        ...new Set(
          this._data.filter((item) => item.groupBy).map((item) => item.groupBy)
        ),
      ];
      const mergedBars = [];
      groups.forEach((g, index) => {
        const groupBars = this._data.filter((item) => item.groupBy === g);
        if (groupBars.length > 1) {
          // merge bars
          const tempBar = new Bar();

          tempBar.type = BarTypes.GROUP_BAR;
          tempBar.id = 'bar' + index;
          tempBar.title = g;
          tempBar.color = '#FF5982';
          tempBar.actualDraw = new Draw(
            groupBars[groupBars.length - 1].actualDraw.from,
            groupBars[0].actualDraw.to
          );
          groupBars.forEach((b) => {
            tempBar.calculated += b.calculated;
            tempBar.value += b.value;
          });

          mergedBars.push({
            title: g,
            bar: tempBar,
          });
        } else {
          mergedBars.push({
            title: g,
            bar: groupBars[0],
          });
        }
      });

      return [
        ...new Set(
          this._data.map((item) => {
            if (item.groupBy) {
              return mergedBars.find((mb) => mb.title === item.groupBy).bar;
            } else {
              return item;
            }
          })
        ),
      ];
    } else {
      return this._data;
    }
  }
  private setWidth(): void {
    this.w =
      this.viewData.length *
        (this.barDetails.width +
          this.barDetails.paddingLeft +
          this.barDetails.paddingRight) +
      this.margin.left +
      this.margin.right;
  }
  private initScales(): void {
    this.x = d3.scaleBand().rangeRound([0, this.width]);

    this.y = d3.scaleLinear().range([this.height, 0]);
  }
  private initSvg(): void {
    this.wrapper.select('svg').remove();
    this.svg = this.wrapper
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr('viewBox', '0 0 ' + this.w + ' ' + this.h);

    this.chart = this.svg
      .append('g')
      .classed('chart-contents', true)
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );
    this.barArea = this.chart.append('g').classed('layers', true);
  }
  private drawAxis(): void {
    this.xAxis = this.chart
      .insert('g', ':first-child')
      .classed('x axis', true)
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(
        d3.axisBottom(this.x).tickPadding(20).tickSizeInner(0).tickSizeOuter(0)
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick')
          .select('line')
          .attr('stroke', '#E3E3E6')
          .attr('y2', (d: string) => {
            const currentEl = this.viewData.find((item) => item.title === d);
            return currentEl.actualDraw.from > 0
              ? -(this.height - this.y(currentEl.actualDraw.from))
              : 0;
          })
      )
      .call((g) => g.selectAll('.tick').select('text').remove())
      .call((g) =>
        g
          .selectAll('.tick')
          .append('foreignObject')
          .attr(
            'width',
            this.barDetails.paddingLeft + this.barDetails.paddingRight
          )
          .attr('id', (d: string) => d)
          .attr('height', 19 * 3)
          .attr(
            'x',
            -(this.barDetails.paddingLeft + this.barDetails.paddingRight) / 2
          )
          .attr('y', 16)
          .append('xhtml:div')
          .style('text-align', 'center')

          .style('font-family', 'SimonKucher, sans-serif')
          .style('font-size', '16px')
          .style('line-height', '19px')
          .html((d: string) => {
            return d;
          })
      );

    this.yAxis = this.chart
      .insert('g', ':first-child')
      .classed('y axis', true)
      .call(
        d3
          .axisLeft(this.y)
          .ticks(8)
          .tickSizeInner(-this.width)
          .tickPadding(15)
          .tickSizeOuter(0)

          .tickFormat((d: any, i) => {
            const currentCurrency = localStorage.getItem(
              commonData.localCurrency
            );
            const pipedVal = this.currencyPipe.transform(
              d,
              currentCurrency,
              '',
              '0.0-0',
              currentCurrency === 'USD' ? 'en-EN' : 'de-DE'
            );
            return pipedVal + ' ' + this.valueSign;
          })
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g.selectAll('.tick').select('line').attr('stroke', '#E3E3E6')
      )
      .call((g) =>
        g
          .selectAll('.tick')
          .select('text')
          .style('font-size', '16px')
          .style('font-family', 'SimonKucher, sans-serif')
      );
  }
  private drawChart(): void {
    this.x.domain(
      this.viewData.map((d) => {
        return d.title;
      })
    );

    this.y.domain([
      0,
      this.valueSign === '%' ? 100 : this.maxVal + this.maxVal * 0.1,
    ]);
    this.barEl = this.barArea
      .selectAll('.bar')
      .data(this.viewData)
      .enter()
      .append('g')
      .classed('bar', true)
      .attr('id', (d: Bar) => d.identifier)
      .style('cursor', (d: Bar) => {
        return d.type === BarTypes.GROUP_BAR ? 'pointer' : 'default';
      })
      .on('click', (e: MouseEvent, d: Bar) => {
        if (d.type === BarTypes.GROUP_BAR) {
          const barIndex = this.viewData.findIndex((bar) => bar.id === d.id);
          this.barClick.emit({
            from: this.viewData[barIndex - 1].id,
            to: this.viewData[barIndex + 1].id,
            title: d.title,
          });
        }
      })
      .on('mouseover', (e: MouseEvent, d: Bar) => {
        // indexing element to show above others .
        this._reorderElTop(d);
        // drawing hover panel
        this._drawOnHoverPanel(d);
      })
      .on('mouseleave', (e, d: Bar) => {
        this.barArea
          .select(`#${d.identifier}`)
          .selectAll('.panel-wrapper')
          .remove();
      });

    this.barEl
      .append('rect')
      .style('fill', (d: Bar) => {
        return d.color;
      })
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('y', (d: Bar) => {
        return this.y(d.actualDraw.to);
      })
      .attr('x', (d: Bar) => {
        return this.x(d.title) + this.barDetails.paddingLeft;
      })
      .attr('width', this.barDetails.width)
      .attr('height', (d: Bar) => {
        return Math.abs(this.y(d.actualDraw.to) - this.y(d.actualDraw.from));
      });
    this.barEl
      .append('rect')
      .style('fill', 'transparent')
      .style('stroke-width', 2)
      .style('stroke', '#FF5982')
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('y', (d: Bar) => {
        if (d.expectedDraw && (d.expectedDraw.from || d.expectedDraw.to)) {
          return this.y(d.expectedDraw.to);
        }
        return 0;
      })
      .attr('x', (d: Bar) => {
        return this.x(d.title) + this.barDetails.paddingLeft;
      })
      .attr('width', this.barDetails.width)
      .attr('height', (d: Bar) => {
        if (d.expectedDraw && (d.expectedDraw.from || d.expectedDraw.to)) {
          return Math.abs(
            this.y(d.expectedDraw.to) - this.y(d.expectedDraw.from)
          );
        }
        return 0;
      });
    this.barEl
      .insert('foreignObject', ':first-child')
      .classed('bar-text', true)
      .attr('y', (d: Bar) => {
        let max = d.actualDraw.to;
        if (d.expectedDraw && d.expectedDraw.to) {
          max = Math.max(d.actualDraw.to, d.expectedDraw.to);
        }
        return this.y(max) - 27;
      })
      .attr('x', (d: Bar) => {
        return this.x(d.title);
      })
      .attr('height', 19)
      .attr('width', this.x.bandwidth())
      .append('xhtml:div')
      .style('text-align', 'center')
      .style('font-weight', (d: Bar) => {
        return d.actualDraw.from === 0 ? 'bold' : 'normal';
      })
      .style('font-size', '16px')
      .style('font-family', 'SimonKucher, sans-serif')
      .html((d: Bar) => {
        return this._decimalPipe(d.calculated);
      });
  }
  private _decimalPipe(val: number): string {
    val = Number(val);
    let pipedVal = Math.round(val);
    const currentCurrency = localStorage.getItem(commonData.localCurrency);
    return this.currencyPipe.transform(
      pipedVal,
      currentCurrency,
      '',
      '0.0-0',
      currentCurrency === 'USD' ? 'en-EN' : 'de-DE'
    );
  }
  private _reorderElTop(d: Bar): void {
    this.svg
      .select('.layers')
      .selectAll('.bar')
      .sort((a: Bar, b: Bar) => {
        // select the parent and sort the path's
        if (a.identifier !== d.identifier) {
          return -1;
        }
        // a is not the hovered element, send "a" to the back
        else {
          return 1;
        } // a is the hovered element, bring "a" to the front
      });
  }
  private _drawOnHoverPanel(d: Bar): void {
    const wrapperWidth = this.wrapper.node().getBoundingClientRect().width;
    const currentPlace = this.x(d.title) + this.barDetails.paddingLeft;
    const allowedSpace = wrapperWidth - currentPlace;
    this._drawPanel(d, allowedSpace, d.type === BarTypes.GROUP_BAR);
  }
  private _drawPanel(d: Bar, allowedSpace: number, isgroup: boolean): void {
    const paddingSize = 45;
    const panelWidth = this._getCalculatedPanelWidth(d, isgroup, paddingSize);
    const minPanelHeight = d.value ? 180 : 60;
    const isInverted = allowedSpace > panelWidth ? false : true;
    const panel = this.barArea
      .select(`#${d.identifier}`)
      .insert('foreignObject', ':nth-child(2)')
      .classed('panel-wrapper', true)
      .attr('y', (d: Bar) => {
        if (isgroup) {
          return 0;
        }
        if (d.expectedDraw && (d.expectedDraw.from || d.expectedDraw.to)) {
          return this.y(Math.max(d.expectedDraw.to, d.actualDraw.to)) - 8;
        }

        return this.y(d.actualDraw.to) - 8;
      })
      .attr('x', (d: Bar) => {
        return (
          (isInverted
            ? this.x(d.title) +
              this.barDetails.paddingLeft +
              paddingSize / 2 -
              panelWidth
            : this.x(d.title) + this.barDetails.paddingLeft) +
          (isInverted ? 8 : -8)
        );
      })
      .style('height', (d: Bar) => {
        if (isgroup) {
          return 455;
        }
        let calculatedHeight = 0;
        if (d.expectedDraw && (d.expectedDraw.from || d.expectedDraw.to)) {
          calculatedHeight =
            Math.max(
              Math.abs(this.y(d.expectedDraw.to) - this.y(d.expectedDraw.from)),
              Math.abs(this.y(d.actualDraw.to) - this.y(d.actualDraw.from))
            ) + 16;
        } else {
          calculatedHeight =
            Math.abs(this.y(d.actualDraw.to) - this.y(d.actualDraw.from)) + 16;
        }
        return calculatedHeight < minPanelHeight
          ? minPanelHeight
          : calculatedHeight;
      })
      .style('width', panelWidth)
      .style(`padding-${isInverted ? 'right' : 'left'}`, paddingSize)
      .style(`padding-${!isInverted ? 'right' : 'left'}`, 8)
      .style('background', '#ECECEE')
      .style('border-radius', '8px');

    const panelContent = panel
      .append('xhtml:div')
      .classed('panel', true)
      .html((d: Bar) => {
        let content = ``;
        if (isgroup) {
          const bars = this._getGroupedBars(d);
          const total = bars.reduce((acc, bar) => {
            return bar.calculated + acc;
          }, 0);
          let iteration = ``;
          bars.forEach((b: Bar) => {
            iteration += `
          <div class="col group" style="min-width: 170px">
            <div class="label">${b.title}</div>
            <div>${this._decimalPipe(b.calculated)}</div>
          </div>`;
          });

          content = `
          <div style="margin-bottom:12px">
              <div class="label" style="font-size:16px">Total</div>
              <div class="val"  style="font-size:16px">${this._decimalPipe(
                total
              )}</div>
          </div>
          <div class="row">
          ${iteration}
          </div>

          `;
        } else {
          if (d.value) {
            content = `
            <div class="d-flex flex-column">
              <div class="group">
                <div class="label">Value calculated</div>
                <div class="val">${this._decimalPipe(d.calculated)}</div>
              </div>

              <div class="group">
                <div class="label">Value from P&L</div>
                <div>${this._decimalPipe(d.value)}</div>
              </div>
              <div class="group">
                <div class="label">Mismatch</div>
                <div class="val">${this._decimalPipe(
                  d.calculated - d.value
                )}</div>
              </div>
            </div>

            `;
          } else {
            {
              content = `
              <div class="d-flex flex-column">
                <div class="group">
                  <div class="label">Value calculated</div>
                  <div class="val">${this._decimalPipe(d.calculated)}</div>
                </div>
              </div>
                `;
            }
          }
        }
        return content;
      });
  }
  private _getCalculatedPanelWidth(
    d: Bar,
    isgroup: boolean,
    paddingSize: number
  ): number {
    // single bar
    if (!isgroup) {
      return 110 + paddingSize;
    }
    // grouped bar
    const groupedBarsLength = this._getGroupedBars(d).length;
    const columnConfig = {
      count: 8,
      width: 200,
    };
    return (
      Math.ceil(groupedBarsLength / columnConfig.count) * columnConfig.width +
      paddingSize
    );
  }

  private _getGroupedBars(d: Bar): Bar[] {
    if (d.type !== BarTypes.GROUP_BAR) {
      return [];
    }
    const barIndex = this.viewData.findIndex(
      (bar) => bar.identifier === d.identifier
    );
    const range = {
      from: this.viewData[barIndex - 1].id,
      to: this.viewData[barIndex + 1].id,
    };
    const indexFrom = this._data.findIndex((i: Bar) => i.id === range.from);
    const indexTo = this._data.findIndex((i: Bar) => i.id === range.to);
    return this._data.slice(indexFrom + 1, indexTo);
  }
}
