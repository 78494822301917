import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/core/interfaces/IResponse';
import {
  Bar,
  Draw,
} from 'src/app/shared/components/double-bar-chart/double-bar-chart.model';

@Injectable({
  providedIn: 'root',
})
export class FinancialImpactService {
  SERVER_URL = environment.apiUrlApp;

  constructor(private http: HttpClient) {}

  public getFinancialImpactCharts(
    negotiationId: string,
    isRelativeCalculation: boolean,
    body?: any
  ): Observable<IFinancialImpactLocalDto> {
    let params = new HttpParams();
    if (isRelativeCalculation !== undefined) {
      params = params.append(
        'isRelativeCalculation',
        String(isRelativeCalculation)
      );
    }
    return (
      this.http
        .post<IResponse<IFinancialImpactDto>>(
          `${this.SERVER_URL}/negotiation/financial-impact/${negotiationId}`,
          body,
          { params }
        )
        // return of(DUMMY_DATA)
        .pipe(
          map((result) => result.response),
          map((result) => this.mapToStackedChart(result)),
          map((result) => this.addGroups(result))
        )
    );
  }
  private mapToStackedChart(
    data: IFinancialImpactDto
  ): IFinancialImpactLocalDto {
    const chartData: IFinancialImpactLocalDto = new IFinancialImpactLocalDto();
    const charts = Object.keys(data);
    charts.forEach((chartKey) => {
      // loop lvl1
      // loop through charts
      const chartBars = data[chartKey] as FinancialImpactChartDTO[];
      let lastDeductedValue = {
        baseline: 0,
        forcast: 0,
      };
      for (const [index, bar] of chartBars.entries()) {
        const waterfallBar = new ModifiedWaterfallBar();
        waterfallBar.baseline = this.calculateBarValues(
          bar,
          lastDeductedValue,
          chartKey,
          'BASELINE'
        );
        waterfallBar.forcast = this.calculateBarValues(
          bar,
          lastDeductedValue,
          chartKey,
          'FORECAST'
        );
        (chartData[chartKey] as ModifiedWaterfallBar[]).push(waterfallBar);
      }
    });
    return chartData;
  }
  private addGroups(data: IFinancialImpactLocalDto): IFinancialImpactLocalDto {
    const charts = Object.keys(data);
    charts.forEach((chartKey) => {
      let groupIndex = {
        baseline: 1,
        forcast: 1,
      };
      data[chartKey] = (data[chartKey] as ModifiedWaterfallBar[]).map(
        (waterfallBar, index, arr) => {
          const modifiedWaterfallBar = new ModifiedWaterfallBar();
          modifiedWaterfallBar.baseline = this.setBarGroups(
            waterfallBar.baseline,
            arr,
            index,
            groupIndex,
            'baseline'
          );
          modifiedWaterfallBar.forcast = this.setBarGroups(
            waterfallBar.forcast,
            arr,
            index,
            groupIndex,
            'forcast'
          );
          return modifiedWaterfallBar;
        }
      );
    });
    return data;
  }
  private calculateBarValues(
    bar: FinancialImpactChartDTO,
    lastDeductedValue: object,
    chartKey: string,
    waterfallType: string
  ): waterfallBar {
    const data = bar.values.find((d) => d.type === waterfallType);
    const barChart: waterfallBar = new waterfallBar();
    barChart.id = bar.id;
    barChart.title = bar.name;
    barChart.calculated = data.calculated;
    barChart.barType = bar.type;
    switch (bar.type) {
      case ExternalBarType[ExternalBarType.SUBSUM]:
        barChart.value = data.value;
        barChart.actualDraw = new Draw(0, data.calculated);
        barChart.expectedDraw = new Draw(0, data.value);
        barChart.color = COLORS.SUBSUM;
        lastDeductedValue[waterfallType] = data.calculated;
        break;
      case ExternalBarType[ExternalBarType.TRADE_TERM]:
        const termDrawFrom = lastDeductedValue[waterfallType] - data.calculated;
        barChart.actualDraw = new Draw(
          termDrawFrom,
          termDrawFrom + data.calculated
        );
        if (bar.conditional === 'YES') {
          barChart.color = COLORS.cond;
        } else {
          barChart.color = COLORS.non_cond;
        }
        lastDeductedValue[waterfallType] = termDrawFrom;
        break;
      case ExternalBarType[ExternalBarType.FIXED_ELEMENT]:
        if (bar.deducted) {
          const drawFrom = lastDeductedValue[waterfallType] - data.calculated;
          barChart.actualDraw = new Draw(drawFrom, drawFrom + data.calculated);
          barChart.color = COLORS.deducted;
          lastDeductedValue[waterfallType] = drawFrom;
        } else {
          barChart.value = data.value;
          barChart.actualDraw = new Draw(0, data.calculated);
          barChart.expectedDraw = new Draw(0, data.value);
          barChart.color = COLORS.SUBSUM;
        }
        break;
    }
    if (['negotiated', 'toBeNegotiation'].includes(chartKey)) {
      // don't show outline in negotiated and tobe negotiation
      barChart.expectedDraw = null;
    }
    return barChart;
  }
  private setBarGroups(
    bar: waterfallBar,
    arr: ModifiedWaterfallBar[],
    index: number,
    groupIndex: object,
    waterfallType: string
  ): waterfallBar {
    if (bar.barType === ExternalBarType[ExternalBarType.TRADE_TERM]) {
      if (
        arr[index + 1][waterfallType].barType ===
          ExternalBarType[ExternalBarType.TRADE_TERM] ||
        arr[index - 1][waterfallType].barType ===
          ExternalBarType[ExternalBarType.TRADE_TERM]
      ) {
        bar.groupBy = 'Group ' + groupIndex[waterfallType];
        if (
          arr[index + 1][waterfallType].barType !==
          ExternalBarType[ExternalBarType.TRADE_TERM]
        )
          groupIndex[waterfallType]++;
      }
    }
    return bar;
  }
}

interface IFinancialImpactDto {
  asIsNegotiation: FinancialImpactChartDTO[];
  negotiated: FinancialImpactChartDTO[];
  toBeNegotiation: FinancialImpactChartDTO[];
}
export class IFinancialImpactLocalDto {
  asIsNegotiation: ModifiedWaterfallBar[] = new Array<ModifiedWaterfallBar>();
  toBeNegotiation: ModifiedWaterfallBar[] = new Array<ModifiedWaterfallBar>();
  negotiated: ModifiedWaterfallBar[] = new Array<ModifiedWaterfallBar>();
}
export class ModifiedWaterfallBar {
  baseline: waterfallBar = new waterfallBar();
  forcast: waterfallBar = new waterfallBar();
  identifier?: string;
}
export class waterfallBar extends Bar {
  barType: string;
}
interface FinancialImpactChartDTO {
  id: string;
  name: string;
  type: string;
  conditional: string;
  deducted: boolean;
  values: FinancialImpactValueDTO[];
}
interface FinancialImpactValueDTO {
  type: string;
  value: number;
  calculated: number;
  mismatch: number;
}
export const COLORS = {
  non_cond: '#B33E5B',
  cond: '#FDA777',
  deducted: '#C99BCA',
  SUBSUM: '#99C4D3',
};
enum ExternalBarType {
  SUBSUM,
  TRADE_TERM,
  FIXED_ELEMENT,
}
