import { Directive, Input, TemplateRef } from '@angular/core';
import { isNil } from 'lodash';
import {
  ColumnFilter,
  DropdownColumnFilterValue,
  TableColumnFilterType,
} from '../filter/table-column-filter.model';

@Directive({
  selector: '[tradestratTableItem]',
})
export class TableItemDirective<T> {
  public constructor(
    public readonly template: TemplateRef<{ $implicit: T; index: number }>
  ) {}
  @Input() public set tradestratTableItemKey(value: string) {
    this.key = value;
  }
  public key!: string;

  @Input() public set tradestratTableItemHeader(value: string) {
    this.header = value;
  }
  public header!: string;

  @Input() public set tradestratTableItemHideHeaderText(value: boolean) {
    this.hideHeaderText = value;
  }
  public hideHeaderText!: boolean;

  @Input() public set tradestratTableItemFilterTrueLabel(value: string) {
    this.filterTrueLabel = value;
  }
  public filterTrueLabel = 'Yes';

  @Input() public set tradestratTableItemFilterFalseLabel(value: string) {
    this.filterFalseLabel = value;
  }
  public filterFalseLabel = 'No';

  @Input() public set tradestratTableItemFilterType(
    value: TableColumnFilterType
  ) {
    this.filterType = value;
  }
  public filterType?: TableColumnFilterType;

  @Input() public set tradestratTableItemFilter(value: ColumnFilter) {
    this.filter = value;
  }
  public filter?: ColumnFilter;

  @Input() public set tradestratTableItemDefaultHidden(value: boolean) {
    this.hidden = value;
    this.visible = !value;
  }
  public hidden = false;
  public visible = true;

  @Input() public set tradestratTableItemSortOn(value: string | false) {
    if (value === false) {
      this.sortingDisabled = true;
    } else {
      this._sortOn = value;
    }
  }
  @Input() public set tradestratTableItemFilterDropdownData(
    value: readonly DropdownColumnFilterValue[] | null
  ) {
    this.filterDropdownData = value ?? undefined;
  }
  public filterDropdownData?: readonly DropdownColumnFilterValue[];
  public get sortOn(): string {
    return this._sortOn || this.key;
  }
  // tslint:disable-next-line: variable-name
  private _sortOn?: string;

  private sortingDisabled = false;

  public get isSortable(): boolean {
    return this.hasFilter && !this.sortingDisabled;
  }

  public get hasFilter(): boolean {
    return !isNil(this.filterType);
  }
  // type of cells value , plain text , or editable text field
  public type = itemTypes.plain;
  @Input() public set tradestratTableItemType(value: itemTypes) {
    this.type = value;
  }
  // type of cells value , plain text , or editable text field
  public customClass: string = '';
  @Input() public set tradestratTableItemCustomClass(value: string) {
    this.customClass = value;
  }
}

enum itemTypes {
  plain,
  textBox,
}
