export interface ClearColumnFilter extends Omit<BaseColumnFilter, 'type'> {
  readonly type: TableColumnFilterType | undefined;
  readonly isCleared: true;
  readonly value: null;
}

export type ColumnFilter =
  | BooleanColumnFilter
  | DateRangeColumnFilter
  | DaysColumnFilter
  | DropdownColumnFilter
  | MultiSelectColumnFilter
  | NumberColumnFilter
  | NumberRangeColumnFilter
  | TextColumnFilter
  | TimeColumnFilter | DateColumnFilter | SelectColumnFilter;

interface BaseColumnFilter {
  readonly type: TableColumnFilterType;
  readonly key: string;
  readonly isCleared: boolean;
}

interface TimeColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Time;
  readonly value: string;
}

interface TextColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Text;
  readonly value: string;
}

interface NumberRangeColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.NumberRange;
  readonly value: {
    readonly min: number | null;
    readonly max: number | null;
  };
}

interface BooleanColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Boolean;
  readonly value: boolean;
}

interface DateRangeColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.DateRange;
  readonly value: {
    readonly start: string;
    readonly end: string;
  };
}

interface NumberColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Number;
  readonly value: number;
}

interface DropdownColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Dropdown;
  readonly value: DropdownColumnFilterValue['id'];
}

interface MultiSelectColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.MultiSelect;
  readonly value: ReadonlyArray<DropdownColumnFilterValue['id']>;
}

interface SelectColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Select;
  readonly value: ReadonlyArray<DropdownColumnFilterValue['id']>;
}

interface DaysColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Days;
  readonly value: string;
}

interface DateColumnFilter extends BaseColumnFilter {
  readonly type: TableColumnFilterType.Date;
  readonly value: string;
}

export interface DropdownColumnFilterValue {
  readonly id: number | string;
  readonly name: string;
}

export enum TableColumnFilterType {
  Boolean = 'boolean',
  DateRange = 'date-range',
  Days = 'days',
  Dropdown = 'dropdown',
  MultiSelect = 'multi-select',
  Number = 'number',
  NumberRange = 'number-range',
  Text = 'text',
  Time = 'time',
  Date = 'date',
  Select = 'select'
}
