import {Component, Input, OnInit} from '@angular/core';
import {interval, Observable, Subject} from 'rxjs';
import {CurrencyPipe} from '@angular/common';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'tradestrat-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {

  @Input() count;
  // @Input() episodeKey;
  // @Input() questionId;
  @Input() stepBy;
  @Input() limitDecreasingTo;
  @Input() showCardRate;
  @Input() currencyInput;
  @Input() maximumInputNumber;
  counterValue;

  private state: Subject<string> = new Subject();
  private cancel: Observable<string>;

  constructor(private currencyPipe: CurrencyPipe) {
    this.cancel = this.state;
  }

  ngOnInit(): void {
    const userInput = 0; // default Values in your App
    this.count = this.limitDecreasingTo ? this.limitDecreasingTo : userInput;
    // userInput.value !== undefined ? userInput.value : userInput;

    if (this.currencyInput) {
      this.counterValue = this.currencyPipe.transform(this.count, 'EUR', 'symbol', '0.2-2', 'de-DE');
      this.count = this.counterValue;
    }
  }

  incrementNumbers(): any {
    if (typeof this.count === 'string') {
      this.count = this.count.replace(/[.]/g, '');
      this.count = parseInt(this.count, 0);
      this.count = this.count > this.maximumInputNumber ? this.maximumInputNumber : this.count;
    }
    this.count = this.count + this.stepBy;
    this.count = this.count > this.maximumInputNumber ? this.maximumInputNumber : this.count;

    this.dispatchCounterAnswer();
  }

  decrementNumbers(): any {
    if (typeof this.count === 'string') {
      this.count = this.count.replace(/[.]/g, '');
      this.count = parseInt(this.count, 0);
    }
    this.count =  this.count - this.stepBy;
    this.count = this.count < this.stepBy ? this.limitDecreasingTo : this.count;

    this.dispatchCounterAnswer();
  }

  /** On click and hold,  unsubscribe when [this.cancel] emit a new state */
  holdExit(): any {
    this.state.next('stop hold');
  }

  onHoldIncrementNumber(): any {
    this.state.next('start hold');

    interval(230).pipe(
      takeUntil(this.cancel),
      tap(() => this.incrementNumbers())
    ).subscribe();
  }

  onHoldDecrementNumber(): any {
    this.state.next('start hold');

    interval(230).pipe(
      takeUntil(this.cancel),
      tap(() => this.decrementNumbers())
    ).subscribe();
  }

  removeCurrencyPipeFormat(): any {
    if (typeof this.count === 'string') {
      this.count = this.count.replace(/[.]/g, '');
    }
    this.count = parseInt(this.count, 0);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  dispatchCounterAnswer(): any {
    if (typeof this.count === 'string') {
      if (this.count === '') {
        this.count = this.limitDecreasingTo;
      }
      else {
        this.count = this.count.replace(/[.]/g, '');
        this.count = parseInt(this.count, 0);
        this.count = this.count > this.maximumInputNumber ? this.maximumInputNumber : this.count;
      }
    }
    else if (this.currencyInput) {
      this.counterValue = this.currencyPipe.transform(this.count, 'EUR', 'symbol', '0.2-2', 'de-DE');
      this.count = this.counterValue;
    }
    if (this.count === 0) {
      this.count = this.limitDecreasingTo;
    }

    let countValue = Number(this.count);
    if (isNaN(Number(this.count))) {
      countValue = this.count;
    }
     // Write your dispatch Actions here

  }


}
