import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'tradestrat-card',
  templateUrl: './card.component.html',
  styleUrls: [ './card.component.scss']
})

export class CardComponent implements OnInit {

  modalRef: BsModalRef;
  currentLang = this.translate.currentLang ? this.translate.currentLang : 'de';

  accordionList = [
    {
      header: {
          en: 'Header 1',
          de: 'Header 1'
      },
      content: {
        en: 'Content 1',
        de: 'Content 1'
      }
    },
    {
      header: {
        en: 'Header 2',
        de: 'Header 2'
      },
      content: {
        en: 'Content 2',
        de: 'Content 2'
      }
    }
  ];

  constructor(private modalService: BsModalService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((LangChangeEvent) => {
      this.currentLang = LangChangeEvent.lang;
    });
  }

  openModalWithClass(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }
}
