import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import { RetailerPerformanceDTO } from 'src/app/features/hq-cbu-dashboard/retailer-performance/retailer-performance.model';

@Component({
  selector: 'tradestrat-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss'],
})
export class BubbleChartComponent implements OnInit {
  constructor(
    private container: ElementRef<HTMLDivElement>,
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe
  ) {}
  private w = 1300;
  private h = 665;
  private scaleHighPercentage = 20;
  private ticksCount = 10;
  private margin = { top: 80, right: 160, bottom: 120, left: 160 };
  public get width(): number {
    return this.w - this.margin.left - this.margin.right;
  }
  public get height(): number {
    return this.h - this.margin.top - this.margin.bottom;
  }
  @Input() set currencySign(v: string) {
    this._currencySign = v;
    this._innitWhenHaveData();
  }
  private _currencySign: string = '$';

  private _xAxisKey: string;
  @Input() set xAxisKey(v: string) {
    this._xAxisKey = v;
    this._innitWhenHaveData();
  }

  private _yAxisKey: string;
  @Input() set yAxisKey(v: string) {
    this._yAxisKey = v;
    this._innitWhenHaveData();
  }

  private _ballSizeKey: string;
  @Input() set ballSizeKey(v: string) {
    this._ballSizeKey = v;
    this._innitWhenHaveData();
  }

  private _data: RetailerPerformanceDTO[];
  @Input() set data(v: RetailerPerformanceDTO[]) {
    this._data = v;
    this._innitWhenHaveData();
  }
  private wrapper: any;
  private svg: any;
  private chart: any;
  private x: any;
  private y: any;
  private xAxis: any;
  private yAxis: any;
  private chartBackground: any;
  private chartBubbles: any;
  private bubbleWrapper: any;
  private tooltip: any;
  ngOnInit(): void {}

  private _innit(): void {
    this.initScales();
    this.initSvg();
    this.drawAxis();
    this.drawBubbles();
  }
  private _innitWhenHaveData(): void {
    if (this._xAxisKey && this._yAxisKey && this._ballSizeKey) this._innit();
  }
  private initScales(): void {
    this.x = d3.scaleLinear().range([0, this.width]);

    this.y = d3.scaleLinear().range([this.height, 0]);
  }
  private initSvg(): void {
    this.wrapper = d3
      .select(this.container.nativeElement)
      .select('.bubble-chart-wrapper');
    this.wrapper.select('svg').remove();
    this.svg = this.wrapper
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr('viewBox', '0 0 ' + this.w + ' ' + this.h);

    this.chart = this.svg
      .append('g')
      .classed('chart-contents', true)
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );
    const ballLabel = this.chart
      .append('g')
      .classed('ball-label', true)

      .attr('transform', 'translate(' + (this.width - 120) + ',-15)');
    ballLabel.append('text').attr('text-anchor', 'start').text('Ball size:');
    ballLabel
      .append('text')
      .attr('text-anchor', 'start')
      .attr('x', '70')
      .style('font-weight', 'bold')
      .text(this.translate.instant('bubbleChartLabels.' + this._ballSizeKey));
  }
  private drawAxis(): void {
    let maxY = Math.max(...this._data.map((item) => item[this._yAxisKey]));
    maxY = maxY + (maxY * this.scaleHighPercentage) / 100;
    let maxX = Math.max(...this._data.map((item) => item[this._xAxisKey]));
    maxX = maxX + (maxX * this.scaleHighPercentage) / 100;
    let minY = Math.min(...this._data.map((item) => item[this._yAxisKey]));

    let minX = Math.min(...this._data.map((item) => item[this._xAxisKey]));

    this.y.domain([minY, maxY]);
    this.x.domain([minX, maxX]);
    this.xAxis = this.chart
      .append('g')
      .classed('x axis', true)
      .attr('transform', 'translate(0,' + (this.height + 15) + ')')
      .call(
        d3
          .axisBottom(this.x)
          .ticks(this.ticksCount)
          .tickSizeInner(-this.height - 15)
          .tickPadding(15)
          .tickSizeOuter(10)

          .tickFormat((d: any, i) => {
            let pipedVal = this.currencyPipe.transform(
              Math.round(d),
              this._currencySign,
              '',
              '0.0-0',
              this._currencySign === '$' ? 'en-EN' : 'de-DE'
            );
            return (
              pipedVal +
              (this._xAxisKey === 'volume' || d == 0 ? '' : this._currencySign)
            );
          })
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g.selectAll('.tick').select('line').attr('stroke', '#E3E3E6')
      )
      .call((g) =>
        g
          .selectAll('.tick')
          .select('text')
          .style('font-size', '16px')
          .style('font-family', 'SimonKucher, sans-serif')
          .attr('transform', 'rotate(-25)')
      );
    //xAxis label
    this.chart
      .append('text')
      .attr('y', this.height + this.margin.bottom - 20)
      .attr('x', this.width / 2)
      .attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text(this.translate.instant('bubbleChartLabels.' + this._xAxisKey));

    this.yAxis = this.chart
      .append('g')
      .classed('y axis', true)
      .attr('transform', 'translate(-15,0)')
      .call(
        d3
          .axisLeft(this.y)
          .ticks(this.ticksCount)
          .tickSizeInner(-this.width - 15)
          .tickPadding(15)
          .tickSizeOuter(10)

          .tickFormat((d: any, i) => {
            let pipedVal = this.currencyPipe.transform(
              Math.round(d),
              this._currencySign,
              '',
              '0.0-0',
              this._currencySign === '$' ? 'en-EN' : 'de-DE'
            );
            return (
              pipedVal +
              (this._yAxisKey === 'volume' || d == 0 ? '' : this._currencySign)
            );
          })
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g.selectAll('.tick').select('line').attr('stroke', '#E3E3E6')
      )
      .call((g) =>
        g
          .selectAll('.tick')
          .select('text')
          .style('font-size', '16px')
          .style('font-family', 'SimonKucher, sans-serif')
      );
    // yAxis label
    this.chart
      .append('text')
      .attr('y', -this.margin.left / 2 - 45)
      .attr('x', -this.height / 2)
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .style('font-weight', 'bold')
      .text(this.translate.instant('bubbleChartLabels.' + this._yAxisKey));
  }

  private drawBubbles(): void {
    this.chartBubbles = this.chart.append('g').classed('chart-bubbles', true);
    this.bubbleWrapper = this.chartBubbles
      .selectAll('.bubble')
      .data(this._data)
      .enter()
      .append('g')
      .classed('bubble', true)
      .attr('id', (d: RetailerPerformanceDTO) => d.id)
      .on('mouseenter', (e: MouseEvent, d: RetailerPerformanceDTO) => {
        // drawing hover panel
        this._reorderElTop(d);
        this._drawOnHoverPanel(d);
      })
      .on('mouseleave', (e, d: RetailerPerformanceDTO) => {
        this.chartBubbles
          .select(`#${d.id}`)
          .selectAll('.panel-wrapper')
          .remove();
        this.chartBubbles
          .select(`#${d.id}`)
          .selectAll('.hover-bubble')
          .remove();
      });
    this.bubbleWrapper
      .append('circle')
      .attr('cx', (d: RetailerPerformanceDTO) => this.x(d[this._xAxisKey]))
      .attr('cy', (d: RetailerPerformanceDTO) => this.y(d[this._yAxisKey]))
      .attr('r', (d: RetailerPerformanceDTO) =>
        this.getBubbleRelativeSize(d[this._ballSizeKey])
      )
      .attr('fill', '#0092A3')
      .style('opacity', '0.5');
    this.bubbleWrapper
      .append('text')
      .classed('bubble-label', true)
      .attr('x', (d: RetailerPerformanceDTO) => this.x(d[this._xAxisKey]))
      .attr(
        'y',
        (d: RetailerPerformanceDTO) =>
          this.y(d[this._yAxisKey]) -
          this.getBubbleRelativeSize(d[this._ballSizeKey]) -
          5
      )
      .attr('text-anchor', 'middle')
      .text((d: RetailerPerformanceDTO) => d.retailer);
  }
  private _drawOnHoverPanel(item: RetailerPerformanceDTO): void {
    this.tooltip = this.chartBubbles
      .select(`#${item.id}`)
      .append('foreignObject')
      .classed('panel-wrapper', true)
      .style('width', 122)
      .style('height', 220)
      .attr(
        'x',
        this.x(item[this._xAxisKey]) +
          this.getBubbleRelativeSize(item[this._ballSizeKey]) +
          10
      )
      .attr('y', this.getPanelYPostion(item));
    this.tooltip.append('xhtml:div').classed('panel', true).html(`
      <div class='group'>
      <div class="label">Retailer</div>
      <div class="val">${item.retailer}</div>
      </div>
      <div class='group'>
      <div class="label">Net sales</div>
      <div class="val">${this.pipeVal(item.netSales)} ${
      this._currencySign
    }</div>
      </div>
      <div class='group'>
      <div class="label">Volume</div>
      <div class="val">${this.pipeVal(item.volume)}</div>
      </div>
      <div class='group'>
      <div class="label">Profit</div>
      <div class="val">${this.pipeVal(item.profit)} ${this._currencySign}</div>
      </div>

    `);
  }
  private pipeVal(v: number): string {
    let pipedVal = this.currencyPipe.transform(
      Math.round(v),
      this._currencySign,
      '',
      '0.0-0',
      this._currencySign === '$' ? 'en-EN' : 'de-DE'
    );
    return pipedVal;
  }
  private getBubbleRelativeSize(actualValue: number): number {
    const maxVal = Math.max(
      ...this._data.map((item) => item[this._ballSizeKey])
    );

    return (actualValue / maxVal) * 50;
  }
  private getPanelYPostion(item: RetailerPerformanceDTO): number {
    const pos =
      this.y(item[this._yAxisKey]) -
      this.getBubbleRelativeSize(item[this._ballSizeKey]);

    const safeArea = this.height - 220;
    return pos > safeArea ? pos - 220 : pos;
  }
  private _reorderElTop(d: RetailerPerformanceDTO): void {
    this.svg
      .selectAll('.bubble')
      .sort((a: RetailerPerformanceDTO, b: RetailerPerformanceDTO) => {
        // select the parent and sort the path's
        if (a.id !== d.id) {
          return -1;
        }
        // a is not the hovered element, send "a" to the back
        else {
          return 1;
        } // a is the hovered element, bring "a" to the front
      });
  }
}
