import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {BaseFieldComponent} from '../base-field.component';

@Component({
    selector: 'tradestrat-switch-button',
    templateUrl: './switch-button.component.html',
    styleUrls: ['./switch-button.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SwitchButtonComponent),
        },
    ],
})
export class SwitchButtonComponent extends BaseFieldComponent<any> implements OnInit {

    @Input() btnType;
    @Input() switchBtnList;
    @Input() labelText;

    currentLang = this.translate.currentLang ? this.translate.currentLang : 'en';

    customBtnList = [
        {
            listText: {
                en: 'Switch btn Text 1 En',
                de: 'Switch btn Text 1 De'
            }
        },
        {
            listText: {
                en: 'Switch btn Text 2 En',
                de: 'Switch btn Text 2 De'
            },
        },
        {
            listText: {
                en: 'Switch btn Text 3 En',
                de: 'Switch btn Text 3 De'
            }
        }
    ];

    checkBoxList = [
        {
            listText: {
                en: 'checkBox Text 1 En',
                de: 'checkBox Text 1 De'
            }
        },
        {
            listText: {
                en: 'checkBox Text 2 En',
                de: 'checkBox Text 2 De'
            },
        },
        {
            listText: {
                en: 'checkBox Text 3 En',
                de: 'checkBox Text 3 De'
            }
        }
    ];

    constructor(private translate: TranslateService) {
        super();
        this.translate.onLangChange.subscribe((LangChangeEvent) => {
            this.currentLang = LangChangeEvent.lang;
        });
    }

    ngOnInit(): void {
    }

    public onInputChange(value): void {
        this.onChange(value);
    }
}
