import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalObject} from './admin-modal-ref';
import {IWaterFullTypesDTO} from '../../../features/admin/data-management/models/data-management-model';

@Component({
  selector: 'tradestrat-admin-modal',
  templateUrl: './admin-modal.component.html',
  styleUrls: ['./admin-modal.component.scss']
})
export class AdminModalComponent implements OnInit, OnChanges {
  @Input() formObject: ModalObject[];
  @Input() headerTitle;
  @Input() typeOfDataManagement: IWaterFullTypesDTO;
  @Output() public cbuSelection = new EventEmitter();
  @Output() public closeModalEvent = new EventEmitter();
  @Output() public checkValidation = new EventEmitter();
  @Output() public submitFunction = new EventEmitter();
  @ViewChild('multiSelectContainer', {read: ElementRef, static: true}) container: ElementRef;
  selectedValueForCheckbox: string[] = [];
  form: FormGroup;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.buildFormForModal(this.formObject);

    this.setValidationBasedOnCondition();

    this.formObject.filter(item => item.validationCondition).map(row => {
      this.form.get(row.validationConditionBasedOn).valueChanges
        .subscribe(value => {
          if (row.valueOfValidationConditionBasedOn.includes(value)) {
            row.validationRequired = true;
            this.form.get(row.field).setValidators([Validators.required]);
            this.form.get(row.field).updateValueAndValidity();
          } else {
            row.validationRequired = false;
            this.form.get(row.field).setValidators(null);
            this.form.get(row.field).updateValueAndValidity();
          }
        });
    });
  }

  closeModal(): void {
    this.closeModalEvent.emit(true);
  }

  onSubmit(): void {
    this.closeModal();

    this.submitFunction.emit(this.form.value);
  }

  buildFormForModal(formObjectData: ModalObject[]): void {
    this.form = new FormGroup(formObjectData?.reduce((acc, field) => {
      acc[field.field] = new FormControl({
        value: field.initValue ? field.initValue : field.type === 'checkbox' ? false : field.type === 'number' ? 0 : '',
        disabled: field.disabled
      });

      if (field.validationRequired && field.validationPattern) {
        acc[field.field].setValidators([Validators.required, Validators.pattern(field.validationPattern)]);
      } else if (field.validationRequired) {
        acc[field.field].setValidators(Validators.required);
      } else if (field.validationPattern) {
        acc[field.field].setValidators(Validators.pattern(field.validationPattern));
      }

      return acc;
    }, {}));
  }

  onItemChange(formControl, event): void {
    this.form.get(formControl).patchValue(event.target ? event.target.checked : event);
  }

  visibilityCondition(field, value): any {
    return value.includes(this.form.get(field).value);
  }

  setValidationBasedOnCondition(): void {
    this.formObject.filter(item => item.validationCondition).map(row => {
      if (row.valueOfValidationConditionBasedOn.includes(this.form.get(row.validationConditionBasedOn).value)) {
        row.validationRequired = true;
        this.form.get(row.field).setValidators([Validators.required]);
        this.form.get(row.field).updateValueAndValidity();
      } else {
        row.validationRequired = false;
        this.form.get(row.field).setValidators(null);
        this.form.get(row.field).updateValueAndValidity();
      }
    });
  }

  selectCbu(field, list, event): void {
    if (field === 'customerBusinessUnit') {
      list.filter(item => item.name === event.value).map(res => {
        this.cbuSelection.emit(res.id);
      });
    }
  }
}
