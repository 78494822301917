import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounce} from 'lodash';
import {BaseFieldComponent} from '../base-field.component';
import {
  ClearColumnFilter,
  ColumnFilter,
  DropdownColumnFilterValue,
  TableColumnFilterType,
} from './table-column-filter.model';
import {en_US, NzI18nService} from 'ng-zorro-antd/i18n';

const inputDebounceDuration = 500;

@Component({
  selector: 'tradestrat-table-column-filter',
  templateUrl: './table-column-filter.component.html',
  styleUrls: ['./table-column-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TableColumnFilterComponent),
    },
  ],
})
export class TableColumnFilterComponent
  extends BaseFieldComponent<any>
  implements OnInit {
  @Input() public key!: string;
  @Input() public trueLabel!: string;
  @Input() public falseLabel!: string;
  @Input() public fullWidthStatus!: boolean;
  @Input() public showSearchIcon!: boolean;
  @Input() public showFilterLabel!: string;
  @Input() public placeHolder = '';
  @Input() public type?: TableColumnFilterType;
  @Input() public dropdownData?: readonly DropdownColumnFilterValue[];
  @Input() public numberOfTagInMultiSelect?: number;

  initalTimeValue;

  constructor(private i18n: NzI18nService) {
    super();
    this.i18n.setLocale(en_US);
    this.initalTimeValue = new Date(0, 0, 0, 0, 0, 0);
  }

  public readonly TableColumnFilterType = TableColumnFilterType;

  public readonly filterText = debounce((rawValue: string): void => {
    const value = rawValue.trim();
    this.onChange(
      value
        ? {
          type: TableColumnFilterType.Text,
          key: this.key,
          value,
          isCleared: false,
        }
        : this.getClearFilter()
    );
  }, inputDebounceDuration);

  public readonly filterNumberRangeMin = debounce(
    (min: number | null): void => {
      this.filterNumberRange(min, this.getCurrentNumberRangeValue('max'));
    },
    inputDebounceDuration
  );

  public readonly filterNumberRangeMax = debounce(
    (max: number | null): void => {
      this.filterNumberRange(this.getCurrentNumberRangeValue('min'), max);
    },
    inputDebounceDuration
  );

  public readonly filterDays = debounce((value: string | null): void => {
    this.onChange(
      value === null
        ? this.getClearFilter()
        : {
          type: TableColumnFilterType.Days,
          key: this.key,
          value,
          isCleared: false,
        }
    );
  }, inputDebounceDuration);

  ngOnInit(): void {
  }

  public filterTime(rawValue: string | null): void {
    const value = rawValue;
    this.onChange(
      value
        ? {
          type: TableColumnFilterType.Time,
          key: this.key,
          value,
          isCleared: false,
        }
        : this.getClearFilter()
    );
  }

  public filterDate(rawValue: string | null): void {
    const value = rawValue;
    this.onChange(
      value
        ? {
          type: TableColumnFilterType.Date,
          key: this.key,
          value,
          isCleared: false,
        }
        : this.getClearFilter()
    );
  }

  private filterNumberRange(min: number | null, max: number | null): void {
    this.onChange(
      min === null && max === null
        ? this.getClearFilter()
        : {
          type: TableColumnFilterType.NumberRange,
          key: this.key,
          value: {min, max},
          isCleared: false,
        }
    );
  }

  public filterBoolean(value: boolean | null): void {
    this.onChange(
      value === null
        ? this.getClearFilter()
        : {
          type: TableColumnFilterType.Boolean,
          key: this.key,
          value,
          isCleared: false,
        }
    );
  }

  public filterNumber(value: number | null): void {
    this.onChange(
      value === null
        ? this.getClearFilter()
        : {
          type: TableColumnFilterType.Number,
          key: this.key,
          value,
          isCleared: false,
        }
    );
  }

  public filterMultiSelect(value: any): void {
    this.onChange(
      !value || !value.length
        ? this.getClearFilter()
        : {
          type: TableColumnFilterType.MultiSelect,
          key: this.key,
          value,
          isCleared: false,
        }
    );
  }

  public getFilter(): ColumnFilter | ClearColumnFilter {
    return this.value ? this.value : this.getClearFilter();
  }

  public clear(): void {
    this.value = this.getClearFilter();
  }

  private getClearFilter(): ClearColumnFilter {
    return {
      type: this.type,
      key: this.key,
      value: null,
      isCleared: true,
    };
  }

  private getCurrentNumberRangeValue(field: 'min' | 'max'): number | null {
    const filter = this.getFilter();
    if (filter.type !== TableColumnFilterType.NumberRange) {
      throw new Error('Filter type must be number range.');
    }
    return filter.isCleared ? null : filter.value[field];
  }
}
