import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tradestrat-user-account-container',
  templateUrl: './user-account-container.component.html',
  styles: [
  ]
})
export class UserAccountContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
