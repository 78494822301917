import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseFieldComponent} from './../base-field.component';

@Component({
  selector: 'tradestrat-textarea',
  templateUrl: 'textarea.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextareaComponent),
    },
  ],
})
export class TextareaComponent extends BaseFieldComponent<string> {
  @Input() public placeholder = '';
  @Input() public rows?: number | string;
  @Input() public updateOn: 'blur' | 'input' = 'input';
  @Input() public maxlength?: number | string;
  @Input() public required?: boolean;

  public onInputChange(value: string): void {
    if (this.updateOn === 'input') {
      this.onChange(value);
    }
  }

  public onBlurChange(value: string): void {
    if (this.updateOn === 'blur') {
      this.onChange(value);
    }
  }
}
