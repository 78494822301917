import {HeaderPattern, moduleHeaderList} from './../../core/utils/header-list';
import {AuthService} from './../../core/services/auth.service';
import {HeaderService} from './../../core/services/header.service';
import {Router} from '@angular/router';
import {Component, OnInit, ViewChild, HostListener, OnDestroy} from '@angular/core';
import {IchildrenPaths} from 'src/app/core/interfaces/ISidebarItems';
import {Subscription} from 'rxjs';

@Component({
  // host: {
  //   '(document:click)': 'dismissMenuOnClickOutside($event)',
  // },
  selector: 'tradestrat-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  sidebar: IchildrenPaths[] = [];

  subMenuIsShown = false;
  menuIsShown = false;
  lockTheSlideMenuStatus = false;

  routingPath = '';
  sideBaraState$: Subscription;
  routePath$: Subscription;
  roles;

  constructor(private headerService: HeaderService, private router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.routePath$ = this.headerService.moduleHeaderItem.subscribe(val => {
      this.routingPath = val;
    });
    this.sideBaraState$ = this.headerService.sideBarState.subscribe(val => this.menuIsShown = val);
    this.sidebar = this.headerService.authorizedList;
    this.roles = localStorage.getItem('roles').split(',');
    this.showMenu(false);
  }

  logout(): void {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('roles');
    this.router.navigate(['/login']);
  }

  toggleMenu(item): void {
    this.router.navigate([item.route[0].path]);
  }

  getParentByClass(event, c: string): any {
    let elm: any = event.target;
    while (!elm.classList.contains(c)) {
      if (elm) {
        elm = elm.parentNode;
        if (elm && !elm.classList) {
          elm = null;
          break;
        } else {
          break;
        }
      } else {
        break;
      }
    }
    return elm;
  }

  hideAllMenus(): void {
    const menus = document.querySelectorAll('.sub-menu');
    if (menus) {
      this.subMenuIsShown = false;
      menus.forEach(menu => {
        menu.parentElement.classList.remove('focused');
        menu.classList.remove('show');
      });
    }
  }

  showMenu(event): void {
    this.headerService.sideBarState.next(event);
    this.menuIsShown = event;
  }

  lockTheSlideMenu(event): void {
    this.lockTheSlideMenuStatus = event;
  }

  ngOnDestroy(): void {
    if (this.sideBaraState$) {
      this.sideBaraState$.unsubscribe();
    }
    if (this.routePath$) {
      this.routePath$.unsubscribe();
    }
  }
}
