import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'tradestrat-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent implements OnInit {
  @Input() title: string;
  @Input() paragraph: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
