import {Observable} from 'rxjs';
import { first} from 'rxjs/operators';

export function getFirstValue<T>(observable: Observable<any>): Promise<any> {
    return observable.pipe(first()).toPromise();
}
    /** checks if remove method is supported to remove a certain element with a compatible function
     * @param element - the element to be removed
     */
    /** removeElementOrRemoveChild */
export function removeElementOrRemoveChild(element: Element): void {
        if (!('remove' in Element.prototype)) {
            element.parentNode.removeChild(element);
        }
        else {
            element.remove();
        }
    }
