import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {IResponse} from './../interfaces/IResponse';
import jwt_decode from 'jwt-decode';
import {Router} from '@angular/router';
import {HeaderService} from './header.service';
import {ISidebarItems} from 'src/app/core/interfaces/ISidebarItems';
import {intersection} from 'lodash';
import {IUserDTO} from '../interfaces/IUser.model';
import * as commonData from '../../../utils/commonData.js';

@Injectable()
export class AuthService {
  SERVER_URL = environment.authServerUrl;
  isLoggedIn = false;
  token = '';
  refreshTokenJWT = '';
  roles = [];

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private headerService: HeaderService
  ) {
  }

  public login(data): Observable<void> {
    return this.httpClient
      .post<any>(`${this.SERVER_URL}/auth/login`, data)
      .pipe(
        map((res: IResponse<any>) => {
          if (res.status.code === 0) {
            this.token =
              res.response.token_type + ' ' + res.response.access_token;
            this.refreshTokenJWT =
              res.response.token_type + ' ' + res.response.refresh_token;
            const tokenInfo = this.getDecodedAccessToken(this.token);
            this.roles = tokenInfo.realm_access.roles;
            localStorage.setItem('access-token', this.token);
            localStorage.setItem('refresh-token', this.refreshTokenJWT);
            localStorage.setItem('roles', this.roles.toString());
            this.router.navigateByUrl('/pages'); // page-not-found;
            this.getUserCurrency().subscribe((currency) => {
              localStorage.setItem(commonData.localCurrency, currency);
            });
          }
        })
      );
  }

  public getUserCurrency(): Observable<string> {
    if (!this.roles.includes('hq')) {
      const url = `${environment.apiUrlApp}/user/info`;
      return this.httpClient
        .get<IResponse<IUserDTO>>(url)
        .pipe(map((result) => {
          localStorage.setItem('user-currency', result.response.customerBusinessUnit.currency);
          return result.response.customerBusinessUnit.currency;
        }));
    } else {
      return;
    }
  }

  public forgetPassword(id): Observable<IResponse<any>> {
    return this.httpClient.post<any>(
      `${this.SERVER_URL}/auth/${id}/forgot-password`,
      {}
    );
  }

  public verifyForgetPassword(id, password): Observable<void> {
    return this.httpClient
      .post<any>(
        `${this.SERVER_URL}/auth/${id}/verify-forgot-password/${password}`,
        {}
      )
      .pipe(
        map((res: IResponse<any>) => {
          if (res.status.code === 0) {
            this.token =
              res.response.token_type + ' ' + res.response.access_token;
            this.refreshTokenJWT =
              res.response.token_type + ' ' + res.response.refresh_token;
            const tokenInfo = this.getDecodedAccessToken(this.token);
            this.roles = tokenInfo.realm_access.roles;
            localStorage.setItem('access-token', this.token);
            localStorage.setItem('refresh-token', this.refreshTokenJWT);
            localStorage.setItem('roles', this.roles.toString());
            this.router.navigateByUrl('/reset-password'); // page-not-found
          }
        })
      );
  }

  public resetPassword(newPassword): Observable<IResponse<any>> {
    return this.httpClient.put<any>(
      `${this.SERVER_URL}/tenant-user/reset-password`,
      {newPassword, temporary: false}
    );
  }

  checkToken(): string {
    this.token = localStorage.getItem('access-token');
    this.roles = localStorage.getItem('roles')
      ? localStorage.getItem('roles').split(',')
      : null;
    if (this.token) {
      this.isLoggedIn = true;
      this.headerService.lists.forEach((item: ISidebarItems) => {
        const checkRole = this.roles.includes(item.role);
        if (checkRole) {
          this.headerService.authorizedList = item.children;
        }
      });
    } else {
      this.isLoggedIn = true;
    }
    return this.token;
  }

  getRoles(): string[] {
    const roles = localStorage.getItem('roles')
      ? localStorage.getItem('roles').split(',')
      : null;
    return roles;
  }

  public hasRoles(roles: string[]): boolean {
    const currentUserRoles = this.getRoles();
    if (currentUserRoles) {
      if (!roles || roles.length === 0) {
        return true;
      } else {
        return intersection(currentUserRoles, roles).length > 0;
      }
    }

    return false;
  }

  refreshToken(): Observable<void> {
    this.refreshTokenJWT = localStorage.getItem('refresh-token');
    return this.httpClient
      .post<any>(`${this.SERVER_URL}/auth/refresh`, {
        token: this.refreshTokenJWT,
      })
      .pipe(
        map((response) => {
          localStorage.setItem('loggedInUser', JSON.stringify(response));
        })
      );
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  logout(): void {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('roles');
    this.router.navigate(['/login']);
  }
}
