import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { GlossaryBucketTerm } from 'src/app/features/glossary/models/glossary-bucket-term.model';

@Component({
  selector: 'tradestrat-term',
  templateUrl: './term.component.html',
  styleUrls: [ './term.component.scss']
})
export class TermComponent implements OnInit {

  public activeTermId: string;
  @Input() terms: GlossaryBucketTerm[];

  @Output() public selectChanges = new EventEmitter();
  @Output() public deleteChanges = new EventEmitter();
  @Output() public editChanges = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }
  delete(item: GlossaryBucketTerm ): void{
    this.deleteChanges.emit(item);
  }
  select(item: GlossaryBucketTerm): void {
    this.selectChanges.emit(item);
    this.activeTermId = item.id;
  }
  edit(item: GlossaryBucketTerm ): void{
    this.editChanges.emit(item);
  }
}
