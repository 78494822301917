import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from '../../core/services/auth.service';
import {HeaderService} from './../../core/services/header.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SharedService} from '../../shared/shared.service';

@Component({
  selector: 'tradestrat-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  menuIsShown = false;
  sideBaraState$: Subscription;
  routeKey;
  logoImage = '';

  constructor(
    public authService: AuthService,
    private headerService: HeaderService,
    private router: Router,
    private sharedService: SharedService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routeKey = event.url.split('/')[1];
      }
    });
  }

  ngOnInit(): void {
    this.logoImage = this.sharedService.logoImage ? this.sharedService.logoImage : './assets/images/Logos/logo.png';
    this.sideBaraState$ = this.headerService.sideBarState.subscribe(val => this.menuIsShown = val);
  }

  ngOnDestroy(): void {
    if (this.sideBaraState$) {
      this.sideBaraState$.unsubscribe();
    }
  }
}
