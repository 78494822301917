import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../core/services/auth.service';
import {TableColumnFilterType} from '../../shared/components/filter/table-column-filter.model';
import {HeaderService} from './../../core/services/header.service';
import {IchildrenPaths} from 'src/app/core/interfaces/ISidebarItems';
import {Router} from '@angular/router';

@Component({
  selector: 'tradestrat-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['homepage.component.scss'],
})
export class HomepageComponent implements OnInit {

  public readonly TableColumnFilterType = TableColumnFilterType;
  cardItems: IchildrenPaths[] = [];

  constructor(public authService: AuthService, private headerService: HeaderService, private router: Router) {
  }

  ngOnInit(): void {
    this.cardItems = this.headerService.authorizedList;
  }

  private updateFilter(value): void {
  }

  routeToLink(event): void {
    this.router.navigateByUrl(event);
  }

}
