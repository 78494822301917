import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Form, FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';


// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import {map, startWith} from 'rxjs/operators';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM, YYYY',
  },
  display: {
    dateInput: 'MMM, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'tradestrat-datepicker',
  templateUrl: './datepicker.component.html',
  styles: [],
  providers: [
    [
      // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
      // application's root module. We provide it at the component level here, due to limitations of
      // our example generation script.
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
      },

      {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input() field: FormControl;
  @Input() label: string;
  @Input() changingPeriodFromDateStatus: boolean;
  @Output() public submitValue = new EventEmitter();
  year = new Date().getFullYear();
  minDate;
  maxDate;
  currentYear = moment().year();
  myDateFilter = (m: Moment | null): boolean => {
    const year = (m || moment()).year();
    return year >= this.currentYear - 2 && year <= this.currentYear + 28;
  };

  constructor() {
  }

  ngOnChanges(): void {
    this.field = new FormControl(moment(this.field.value));
  }

  ngOnInit(): void {
    this.minDate = new Date(this.year - 2, 0, 1);
    this.maxDate = new Date(this.year + 28, 12, 1);

    this.field.valueChanges.subscribe(res => {
      if (res) {
        this.OnDateChange();
      }
    });
  }

  chosenYearHandler(normalizedYear, field: FormControl): void {
    const ctrlValue = field.value;
    ctrlValue.year(normalizedYear.year());
    field.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth, field: FormControl, datepicker: MatDatepicker<any>): void {
    const ctrlValue = field.value;

    ctrlValue.month(normalizedMonth.month());
    field.setValue(ctrlValue);

    datepicker.close();

    this.OnDateChange();
  }

  OnDateChange(): void {
    this.submitValue.emit(moment(this.field.value).toDate());
  }
}
