import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { SharedService } from './../../../shared/shared.service';

@Component({
  selector: 'tradestrat-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  loginForm: FormGroup;
  successfulLogin = false;
  failedLogin = false;
  matchingPass = false;
  loginObject = [];
  isSubmitted = false;
  loginBackground = '';
  logoImage = '';

  constructor(private router: Router, private authService: AuthService, private sharedService: SharedService) {

  }

  ngOnInit(): void {
    this.logoImage = this.sharedService.logoImage ? this.sharedService.logoImage : './assets/images/Logos/logo.png';

    this.loginBackground = this.sharedService.loginBackground ? this.sharedService.loginBackground : './assets/images/BackgroundImages/login.png';
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }


  onSubmit(): void {
      this.matchingPass = false;
      this.failedLogin = false;
      this.authService.login(this.loginForm.value).subscribe((res: any) => {
        if (!res){
          this.failedLogin = true;
        }
      }, error => this.failedLogin = true);
  }
}
