export class Bar {
  id: string;
  title: string;
  value = 0;
  calculated = 0;
  color: string;
  actualDraw: Draw;
  expectedDraw?: Draw;
  groupBy?: string;
  type: BarTypes = BarTypes.DATA_BAR;
  identifier?: string;
}
export class Draw {
  from: number;
  to: number;
  constructor(from: number, to: number) {
    this.from = from;
    this.to = to;
  }
}

export enum BarTypes {
  DATA_BAR,
  GROUP_BAR,
}

export interface IExpandBar {
  from: string;
  to: string;
  title: string;
}
