import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './core/components/signin/signin.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { HomepageComponent } from './layout/homepage/homepage.component';
import { CommonViewComponent } from './shared/common-component-view/common-component-view.component';
import { ResetPasswordComponent } from './core/components/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './core/components/forget-password/forget-password.component';
import { AuthGuard } from './core/guards/auth.guard';
import { HQGuard } from './core/guards/hq.guard';
import { KAMGuard } from './core/guards/kam.guard';
import { CBUGuard } from './core/guards/cbu.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { DataUploadGuard } from './core/guards/data-upload.guard';

const routes: Routes = [
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'login', component: SigninComponent, canActivate: [AuthGuard] },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reset-password',
    canActivate: [AuthGuard],
    component: ResetPasswordComponent,
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Home' },
    children: [
      {
        path: '',
        component: HomepageComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: '' },
      },
      { path: 'common-components', component: CommonViewComponent },
      {
        path: 'HQ',
        canActivate: [AuthGuard, HQGuard],
        data: { breadcrumb: '' },
        loadChildren: () =>
          import('./features/hq/hq.module').then((m) => m.HQModule),
      },
      {
        path: 'commercialPolicy',
        canActivate: [AuthGuard, CBUGuard],
        data: { breadcrumb: 'Commercial Policies' },
        loadChildren: () =>
          import('./features/cbu/cbu.module').then((m) => m.CBUModule),
      },
      {
        path: 'KAM',
        canActivate: [AuthGuard, KAMGuard],
        data: { breadcrumb: 'Negotiations' },
        loadChildren: () =>
          import('./features/kam/kam.module').then((m) => m.KAMModule),
      },
      {
        path: 'KAM/dashboard',
        canActivate: [AuthGuard, KAMGuard],
        data: { breadcrumb: 'Retailer Negotiation Monitoring' },
        loadChildren: () =>
          import('./features/kam-dashboard/kam-dashboard.module').then(
            (m) => m.KamDashboardModule
          ),
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard, CBUGuard],
        data: { breadcrumb: 'Dashboards' },
        loadChildren: () =>
          import('./features/hq-cbu-dashboard/hq-cbu-dashboard.module').then(
            (m) => m.HqCbuDashboardModule
          ),
      },
    ],
  },
  {
    path: 'data-upload',
    canActivate: [AuthGuard, DataUploadGuard],
    data: { breadcrumb: 'Data Upload' },
    loadChildren: () =>
      import('./features/data-upload/data-upload.module').then(
        (m) => m.DataUploadModule
      ),
  },
  {
    path: 'tenant',
    loadChildren: () =>
      import('./features/tenant-management/tenant-management.module').then(
        (m) => m.TenantManagementModule
      ),
  },
  {
    path: 'administration',
    data: { breadcrumb: 'Administration' },
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminModule),
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
