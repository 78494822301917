import { HeaderPattern } from './../utils/header-list';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { ISidebarItems } from './../interfaces/ISidebarItems';
import { IchildrenPaths } from 'src/app/core/interfaces/ISidebarItems';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  router$: Subscription;
  moduleHeaderItem: BehaviorSubject<string> = new BehaviorSubject('/pages');
  sideBarState: BehaviorSubject<any> = new BehaviorSubject(false);

  lists: ISidebarItems[] = [
    {
      role: 'hq',
      description: 'homeDescription',
      route: '/pages',
      children: [
        {
          title: 'glossaryDefinition',
          sideBarTitle: 'glossaryDefinition',
          active: false,
          route: [{ title: 'glossaryDefinition', path: '/pages/HQ/glossary' }],
          icon: 'bi bi-clipboard',
        },
        {
          title: 'monitoringDashboard',
          sideBarTitle: 'monitoringDashboard',
          active: false,
          route: [
            { title: 'globalDashboard', path: '', disabled: true },
            {
              title: 'cbuDashboard',
              path: '/pages/dashboard',
              disabled: false,
            },
          ],
          icon: 'bi bi-graph-up',
        },
      ],
    },
    {
      role: 'cbu',
      description: 'homeDescription',
      route: '/pages',
      children: [
        {
          title: 'commercialPolicyDefinition',
          sideBarTitle: 'commercialPolicy',
          active: false,
          route: [
            { title: 'commercialPolicy', path: '/pages/commercialPolicy' },
          ],
          icon: 'bi bi-clipboard-check',
        },
        {
          title: 'negotiationPlan&Execution',
          sideBarTitle: 'negotiation',
          active: false,
          disabled: true,
          route: [
            { title: 'driftAllocation', path: '' },
            { title: 'retailerNegotiation', path: '' },
          ],
          icon: 'bi bi-file-earmark-ruled',
        },
        {
          title: 'tradeTermsAssessment&Monitoring',
          sideBarTitle: 'monitoring',
          active: false,
          route: [{ title: 'dashboards', path: '/pages/dashboard' }],
          icon: 'bi bi-graph-up',
        },
      ],
    },
    {
      role: 'kam',
      description: 'homeDescription',
      route: '/pages',
      children: [
        {
          title: 'negotiationPlanning',
          sideBarTitle: 'negotiation',
          active: false,
          route: [{ title: 'negotiations', path: '/pages/KAM' }],
          icon: 'bi bi-file-earmark-ruled',
        },
        {
          title: 'NegotiationMonitoring',
          sideBarTitle: 'monitoring',
          active: false,
          route: [
            { title: 'retailerNegotiation', path: '/pages/KAM/dashboard' },
            { title: 'tracking', path: '', disabled: true },
          ],
          icon: 'bi bi-graph-up',
        },
      ],
    },
  ];
  authorizedList: IchildrenPaths[] = [];

  constructor(private router: Router) {
    this.router$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const modulePath = event.urlAfterRedirects;
        this.moduleHeaderItem.next(modulePath);

        if (this.authorizedList.length) {
          this.authorizedList = [
            ...this.authorizedList.map((item) => {
              const checkPath = item.route.filter(
                (ele) => ele.path === modulePath
              );

              if (checkPath.length) {
                item.active = true;
              } else {
                item.active = false;
              }
              return item;
            }),
          ];
        }
      }
    });
  }
}
