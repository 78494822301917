module.exports = {
  monthList: [
    {
      code: '1',
      name: 'January'
    },
    {
      code: '2',
      name: 'February'
    },
    {
      code: '3',
      name: 'March'
    },
    {
      code: '4',
      name: 'April'
    },
    {
      code: '5',
      name: 'May'
    },
    {
      code: '6',
      name: 'June'
    },
    {
      code: '7',
      name: 'July'
    },
    {
      code: '8',
      name: 'August'
    },
    {
      code: '9',
      name: 'September'
    },
    {
      code: '10',
      name: 'October'
    },
    {
      code: '11',
      name: 'November'
    },
    {
      code: '12',
      name: 'December'
    },
  ],
  yearList: [
    {
      code: '2020',
      name: '2020'
    },
    {
      code: '2021',
      name: '2021'
    },
    {
      code: '2022',
      name: '2022'
    },
    {
      code: '2023',
      name: '2023'
    },
  ],
  negotiationAsIsKey: 'asIsNegotiation',
  negotiation: 'negotiation',
  negotiationToBeKey: 'toBeNegotiation',
  productAttributeID: 'PRODUCT_ATTRIBUTES',
  localCurrency:'currency',
  SKUSettings:'skuSettings',
  SKUFilters:'skuFilters'
}
