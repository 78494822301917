import {
  Component,
  OnInit,
  DoCheck,
  Input,
  ElementRef,
  IterableDiffers,
} from '@angular/core';
import * as d3 from 'd3';
import { NegotiationPlanning } from 'src/app/features/kam/kam-details/planning-and-prioritization/negotiation-planning.model';

@Component({
  selector: 'tradestrat-two-level-dot-chart',
  templateUrl: './two-level-dot-chart.component.html',
  styleUrls: ['./two-level-dot-chart.component.scss'],
})
export class TwoLevelDotChartComponent implements OnInit, DoCheck {
  @Input() data: NegotiationPlanning[] = new Array<NegotiationPlanning>();

  private w = 600;
  private h = 400;
  private margin = { top: 20, right: 20, bottom: 80, left: 80 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;

  private x: any;
  private y: any;
  private chart: d3.Selection<any, any, any, any>;
  private svg: d3.Selection<any, any, any, any>;
  private zones: d3.Selection<any, any, any, any>;
  private bubbles: d3.Selection<any, any, any, any>;
  private xAxis: any;
  private yAxis: any;
  @Input() xTitle: any = 'Gross sales (%)';
  @Input() yTitle: any = 'Delta total discount (%)';
  @Input() zoneList: AreaZone[] = [
    {
      title: 'Best performers',
      position: 'top',
      color: '#007e65',
      bgColor: '#b2e5db',
    },
    {
      title: 'Worst performers',
      position: 'bottom',
      color: '#a31446',
      bgColor: '#f4bace',
    },
  ];
  private tooltip: d3.Selection<any, any, any, any>;

  constructor(
    private container: ElementRef,
    private iterableDiffers: IterableDiffers
  ) {}

  ngOnInit(): void {
    this.initScales();
    this.initSvg();
    this.drawZones();
    this.drawAxis();
  }
  ngDoCheck(): void {
    const changes = this.iterableDiffers.find(this.data);
    if (changes) {
      this.drawBubbles();
    }
  }
  private initScales(): void {
    this.x = d3.scaleLinear().range([0, this.width]).domain([0, 3]);
    this.y = d3.scaleLinear().range([this.height, 0]).domain([-4, 4]);
  }

  private initSvg(): void {
    this.svg = d3
      .select(this.container.nativeElement)
      .select('.chart-container')
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr('viewBox', '0 0 ' + this.w + ' ' + this.h);

    this.chart = this.svg
      .append('g')
      .classed('chart-contents', true)
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );
    this.tooltip = d3
      .select(this.container.nativeElement)
      .select('.chart-container')
      .append('div')
      .attr('class', 'tooltip matrix')
      .style('opacity', 0);
  }
  private drawZones(): void {
    this.zones = this.chart
      .append('g')
      .classed('zones', true)
      .selectAll('.zone')
      .data(this.zoneList)
      .enter()
      .append('g')
      .classed('zone', true);
    this.zones
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height / 2)
      .attr('x', (d) => {
        return 0;
      })
      .attr('y', (d) => {
        return this.getZonePosition(d.position).y;
      })
      .attr('fill', (d) => d.bgColor);

    this.zones
      .append('text')
      .text((d) => d.title)
      .style('stroke', 'none')
      .style('font-size', '1.1rem')
      .style('font-weight', 'bold')
      .attr('fill', (d) => {
        return d.color;
      })
      .style('text-anchor', (d) => {
        switch (d.position) {
          case 'tr':
          case 'br':
            return 'end';

          default:
            return 'start';
        }
      })
      .attr('x', (d) => {
        return this.getZoneTitlePosition(d.position).x;
      })
      .attr('y', (d) => {
        return this.getZoneTitlePosition(d.position).y;
      });
  }
  private getZonePosition(position: string): any {
    switch (position) {
      case 'top':
        return { x: 0, y: 0 };
      case 'bottom':
        return { x: 0, y: this.height / 2 };
    }
  }
  private getZoneTitlePosition(position: string): any {
    const space = 5;
    switch (position) {
      case 'top':
        return { x: space, y: 20 };

      case 'bottom':
        return { x: space, y: this.height - space };
    }
  }
  private drawAxis(): void {
    this.xAxis = this.chart
      .append('g')
      .classed('x axis', true)
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(
        d3
          .axisBottom(this.x)
          .ticks(5)
          .tickSizeInner(10)
          .tickSizeOuter(10)
          .tickPadding(15)
      );

    this.chart
      .append('text')
      .attr('y', this.height + 60)
      .attr('x', this.width / 2)
      .classed('axis-title', true)
      .style('text-anchor', 'middle')
      .style('stroke', 'none')
      .text(this.xTitle);

    this.yAxis = this.chart
      .append('g')
      .classed('y axis', true)
      .call(
        d3
          .axisLeft(this.y)
          .ticks(8)
          .tickSizeInner(10)
          .tickSizeOuter(10)
          .tickPadding(15)
      );

    this.chart
      .insert('text', ':first-child')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - 60)
      .attr('x', 0 - this.height / 2)
      .style('text-anchor', 'middle')
      .style('stroke', 'none')
      .classed('axis-title', true)
      .text(this.yTitle);

    this.bubbles = this.chart.append('g').classed('bubbles', true);
  }
  private drawBubbles(): void {
    this.bubbles.selectAll('.bubble').remove();
    this.bubbles
      .selectAll('.bubble')
      .data(this.data)
      .enter()
      .append('circle')
      .classed('bubble', true)
      .attr('cx', (d) => this.x(d.costToManufacturer))
      .attr('cy', (d) => this.y(d.valueToRetailer))
      .attr('r', (d) => 15)
      .attr('fill', '#003549')
      .on('mouseenter', (event, d) => {
        this.tooltip.transition().duration(200).style('opacity', 0.9);
        this.tooltip
          .html(`Goal No. ${d.index + 1}<br>Importance : ${d.importance}`)
          .style('left', this.x(d.costToManufacturer) + 'px')
          .style(
            'top',
            this.y(d.valueToRetailer) - d.importance * 5 - 20 + 'px'
          );
      });
  }
}
interface AreaZone {
  title: string;
  position: string;
  color: string;
  bgColor: string;
}
