import {Component, Input, OnChanges, OnInit} from '@angular/core';
import * as d3 from 'd3';


@Component({
  selector: 'tradestrat-dots-chart',
  templateUrl: './dots-chart.component.html',
  styles: []
})
export class DotsChartComponent implements OnInit, OnChanges {
  @Input() data;
  @Input() discountLinePoints;
  private svg;
  private margin = 50;
  private width = 750 - (this.margin * 2);
  private height = 400 - (this.margin * 2);
  bisect: any;
  focus: any;
  focusText: any;
  xAxis: any;
  yAxis: any;

  constructor() {
  }

  ngOnChanges(): void {
    d3.selectAll('svg').remove();
    this.createSvg();
    this.drawPlot();
    if (this.discountLinePoints.length) {
      this.drawDiscountLine();
    }
  }

  ngOnInit(): void {
  }

  createSvg(): void {
    this.svg = d3.select('figure#scatter')
      .append('svg')
      .attr('width', this.width + (this.margin * 2))
      .attr('height', this.height + (this.margin * 2))
      .append('g')
      .attr('transform', 'translate(' + this.margin + ',' + this.margin + ')');
  }

  private drawPlot(): void {
    // Add X axis
    const x = d3.scaleLinear()
      .domain([0, 3000])
      .range([0, this.width]);
    this.svg.append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(x).ticks(7).tickFormat(d3.format('d')));

    // Add Y axis
    const y = d3.scaleLinear()
      .domain([0, 60])
      .range([this.height, 0]);
    this.svg.append('g')
      .call(d3.axisLeft(y).ticks(7));

    const xAxisGrid = d3.axisBottom(x).tickSize(-this.height).tickFormat(d3.format(''));
    this.svg.append('g')
      .attr('class', 'x axis-grid')
      .attr('style', 'opacity: 0.1')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(xAxisGrid.ticks(7));

    const yAxisGrid = d3.axisLeft(y).tickSize(-this.width).tickFormat(d3.format(''));
    this.svg.append('g')
      .attr('class', 'y axis-grid')
      .attr('style', 'opacity: 0.1')
      .call(yAxisGrid.ticks(7));

    // Add dots
    const dots = this.svg.append('g');
    dots.selectAll('dot')
      .data(this.data)
      .enter()
      .append('circle')
      .attr('cx', d => x(d.Released))
      .attr('cy', d => y(d.Stars))
      .attr('r', 7)
      .style('opacity', .5)
      .style('fill', '#69b3a2');

    // Add labels
    dots.selectAll('text')
      .data(this.data)
      .enter()
      .append('text')
      .text(d => d.Framework)
      .attr('x', d => x(d.Released))
      .attr('y', d => y(d.Stars));


  }

  private drawDiscountLine(): void {
    this.xAxis = d3.scaleLinear()
      .domain([0, 3000])
      .range([0, this.width]);
    this.svg.append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(this.xAxis).ticks(7).tickFormat(d3.format('d')));

    // Add Y axis
    this.yAxis = d3.scaleLinear()
      .domain([0, 60])
      .range([this.height, 0]);
    this.svg.append('g')
      .call(d3.axisLeft(this.yAxis).ticks(7));

    // This allows to find the closest X index of the mouse:
    this.bisect = d3.bisector((d: any) => {
      return d.y;
    }).left;

    // Create the circle that travels along the curve of chart
    this.focus = this.svg
      .append('g')
      .append('circle')
      .style('fill', 'none')
      .attr('stroke', 'black')
      .attr('r', 8.5)
      .style('opacity', 0);

    // Create the text that travels along the curve of chart
    this.focusText = this.svg
      .append('g')
      .append('text')
      .style('opacity', 0)
      .attr('text-anchor', 'left')
      .attr('alignment-baseline', 'middle');

    const line = d3.line()
      .x((d: any) => {
        return this.xAxis(d.x);
      })
      .y((d: any) => {
        return this.yAxis(d.y);
      });

    this.svg.append('path')
      .datum(this.discountLinePoints)
      .attr('fill', 'none')
      .attr('stroke', '#69b3a2')
      .attr('width', '3')
      .attr('stroke-dasharray', 6)
      .attr('d', d => {
        return line(d.map(item => {
          return {x: item.x, y: item.y};
        }));
      })
      .on('mouseover', this.mouseover)
      .on('mousemove', this.mousemove)
      .on('mouseout', this.mouseout);
  }

  mouseover = (d) => {
    this.focus.style('opacity', 1);
    this.focusText.style('opacity', 1);
  }

  mousemove = (d) => {
    // recover coordinate we need
    const x0 = this.xAxis.invert([d.y, d.x][0]);
    const i = this.bisect(this.discountLinePoints, x0, 1);
    const selectedData = this.discountLinePoints[i];
    this.focus
      .attr('cx', this.xAxis(selectedData.Released))
      .attr('cy', this.yAxis(selectedData.Stars));
    this.focusText
      .html('x:' + selectedData.x + '  -  ' + 'y:' + selectedData.Stars)
      .attr('x', this.xAxis(selectedData.Released) + 15)
      .attr('y', this.yAxis(selectedData.Stars));
  }

  mouseout = () => {
    this.focus.style('opacity', 0);
    this.focusText.style('opacity', 0);
  }
}
