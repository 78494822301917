import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tradestrat-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],

})
export class ResetPasswordComponent implements OnInit {
  doNotMatch = false;
  error = false;
  success = false;
  account: any;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  passwordForm: FormGroup;
  editError = false;
  editSuccess = false;

  constructor(private passwordService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.initialForm();
  }
  initialForm(): void {
    this.passwordForm = new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50)
      ]),
      confirmPassword: new FormControl('', Validators.required),
    });
  }

  changePassword(): void {


    if (this.newPassword !== this.confirmPassword) {
      this.error = true;
      this.success = false;
      this.doNotMatch = true;
      this.editError = true;
    } else {
      this.error = false;
      this.doNotMatch = false;
      this.editError = false;
      this.passwordService
        .resetPassword(this.newPassword)
        .subscribe(
          () => {
            this.router.navigate(['/login']);
          },
          () => {
            this.success = false;
            this.error = true;
          }
        );
    }
  }
  resetForm(): void{
    this.initialForm();
  }
}
